import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { withApollo, WithApolloClient } from 'react-apollo';
import {
  Button,
  Col,
  Container,
  Row 
} from 'reactstrap';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { FormInput } from './../../../../ui/FormInput/FormInput';
import { ActivationMySmartBatteryMylight } from './../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import { ISnackBarParams } from './../../../../stores/userFeedback/snackbarStore';
import { EditElectricalInstallationStore } from './../../../../stores/installations/editElectricalInstallationStore';

interface IProps {
  t: TranslationFunction;
  customerId: string;
  toggle: any;
}

interface IStoresProps {
  msb: ActivationMySmartBatteryMylight,
  electricalInstallation: EditElectricalInstallationStore,
  showSnackBar: (params: ISnackBarParams) => void,
}

const InstallationSetPdlModalSection = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    msb: stores.activationMySmartBatteryMylight,
    electricalInstallation: stores.editElectricalInstallation,
    showSnackBar: stores.snackBar.showSnackBar,
  }),
  ({ 
  t,
  client,
  customerId,
  toggle,
  msb,
  electricalInstallation,
  showSnackBar,
 }: WithApolloClient<IProps & IStoresProps>) => {
  const [pdlNumber, setPdlNumber] = useState<string>('');

  const onPdlNumberChange = (value: string) => {
    setPdlNumber(value);
  };

  const assignPdl = async () => {
    const response = await msb.updatePdlNumber(client, customerId, pdlNumber, true);
    await electricalInstallation.setPdlNumber(pdlNumber);

    if (response) {
      showSnackBar({ text: t('Customer:setPdlSuccess'), type: 'success' });

      toggle();
    }
  };

  useEffect(() => {
    if (msb.pdlNumberInputState === 'error') {
      showSnackBar({ text: t('Customer:setPdlError'), type: 'error' });
    }
  }, [msb.pdlNumberInputState]);

  return (
    <Container>
      <Row>
        <Col xs={12} className="font-weight-bold">
          <p>{ t('Customer:setPdl') }</p>
        </Col>

        <Col xs={12} className="">
          <p>
            { t('Customer:setPdlDescription') }
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12}>
          <FormInput
            id="customerMobilePhone"
            type="text"
            name={ 'PDL' }
            value={ pdlNumber }
            onChange={ onPdlNumberChange }
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="text-right">
          <Button
              id={ `button-assign-pdl` }
              className={ 'btn-primary' }
              color="primary"
              onClick={ assignPdl }
              >
              { t('Customer:setPdlButton') }
          </Button>
        </Col>
      </Row>
    </Container>
  );
});

export default translate(['Common', 'Customer'])(
  withApollo(InstallationSetPdlModalSection)
);