import gql from 'graphql-tag';
import { action, computed, observable } from 'mobx';
import { EnergyDistributor, MSB } from '../../graphql/graphql.schema';
import { WithApolloClient } from 'react-apollo';
import { t } from 'i18next';
import { MsbActivationInputState } from '../../components/customers/installation/battery/activation/MsbInputText';
import { GET_CUSTOMER } from '../../components/customers/identity/IdentityQuery';

const UPDATE_PDL_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updatePdlNumberByCustomerId(
    $customerId: String!
    $pdlNumber: String!
    $resendInstallationCompletedMessage: Boolean
  ) {
    updatePdlNumberByCustomerId(
      customerId: $customerId
      pdlNumber: $pdlNumber
      resendInstallationCompletedMessage: $resendInstallationCompletedMessage
    ) {
      pdlNumber
      pdlNumberModifiedAt
    }
  }
`;

const UPDATE_SERIAL_NUMBER_BY_CUSTOMER_ID = gql`
  mutation updateSerialNumberByCustomerId(
    $customerId: String!
    $serialNumber: String!
  ) {
    updateSerialNumberByCustomerId(
      customerId: $customerId
      serialNumber: $serialNumber
    ) {
      serialNumber
      serialNumberModifiedAt
    }
  }
`;

export class ActivationMySmartBatteryMylight {
  @observable public volume: number = 0;
  @observable public energyDistributor: EnergyDistributor | null = null;
  @observable public serialNumber: string = '';
  @observable public serialNumberSaved: string = '';
  @observable public serialNumberModifiedAt: Date | null = null;
  @observable
  public serialNumberInputState?: MsbActivationInputState = undefined;
  @observable public pdlNumber: string = '';
  @observable public pdlNumberSaved: string = '';
  @observable public pdlNumberModifiedAt: Date | null = null;
  @observable public pdlNumberInputState?: MsbActivationInputState = undefined;

  @computed
  public get isMsbMylightFormValid(): boolean {
    return (
      this.serialNumberInputState !== 'error' &&
      this.pdlNumberInputState !== 'error'
    );
  }

  @action.bound
  public async updatePdlNumber(
    client: WithApolloClient<any>,
    customerId: string,
    pdlNumber: string,
    resendInstallationCompletedMessage: boolean = false
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PDL_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          pdlNumber,
          resendInstallationCompletedMessage
        }
      });
      this.pdlNumber = data.updatePdlNumberByCustomerId.pdlNumber;
      this.pdlNumberSaved = data.updatePdlNumberByCustomerId.pdlNumber;
      this.pdlNumberModifiedAt =
        data.updatePdlNumberByCustomerId.pdlNumberModifiedAt;
      this.pdlNumberInputState = 'success';
      return Promise.resolve(data.updatePdlNumberByCustomerId);
    } catch (error) {
      this.pdlNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async updateSerialNumber(
    client: WithApolloClient<any>,
    customerId: string,
    serialNumber: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_SERIAL_NUMBER_BY_CUSTOMER_ID,
        variables: {
          customerId,
          serialNumber
        }
      });
      this.serialNumber = data.updateSerialNumberByCustomerId.serialNumber;
      this.serialNumberSaved = data.updateSerialNumberByCustomerId.serialNumber;
      this.serialNumberModifiedAt =
        data.updateSerialNumberByCustomerId.serialNumberModifiedAt;
      this.serialNumberInputState = 'success';
      return Promise.resolve(data.updateSerialNumberByCustomerId);
    } catch (error) {
      this.serialNumberInputState = 'error';
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public setActivationMsbMylight(msb: MSB): void {
    this.clearActivationMsbMylight();
    if (msb.volume) {
      this.volume = msb.volume;
    }
    this.energyDistributor = msb.energyDistributor || null;
    if (msb.boxesSerialNumber) {
      this.serialNumber = msb.boxesSerialNumber[0];
      this.serialNumberSaved = msb.boxesSerialNumber[0];
      this.serialNumberModifiedAt = msb.serialNumberModifiedAt;
    }
    if (msb.pdlNumber) {
      this.pdlNumber = msb.pdlNumber;
      this.pdlNumberSaved = msb.pdlNumber;
      this.pdlNumberModifiedAt = msb.pdlNumberModifiedAt;
    }
  }

  @action.bound
  public clearActivationMsbMylight(): void {
    this.volume = 0;
    this.energyDistributor = null;
    this.serialNumber = '';
    this.serialNumberSaved = '';
    this.serialNumberModifiedAt = null;
    this.serialNumberInputState = undefined;
    this.pdlNumber = '';
    this.pdlNumberSaved = '';
    this.pdlNumberModifiedAt = null;
    this.pdlNumberInputState = undefined;
  }
}

const activationMySmartBatteryMylight: ActivationMySmartBatteryMylight = new ActivationMySmartBatteryMylight();
export default activationMySmartBatteryMylight;
