import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { Button } from 'reactstrap';
import { safeMobxInject } from './../../../../stores/storeInjectionHelpers';
import { Customer } from './../../../../graphql/graphql.schema';
import InstallationSettingsModal from './InstallationSettingsModal';
import { Profile } from './../../../../stores/profile';

interface IProps {
  t: TranslationFunction;
  customer: Customer;
}

interface IStoresProps {
  profile: Profile,
}

const InstallationSettings = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    profile: stores.Store.profile,
  }),
  ({ 
  t,
  customer,
  profile,
 }: IProps & IStoresProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const showInstallationSettingsModal = () => {
    setModalOpen(!isModalOpen);
  };
  
  return (
    <>
      { profile.isAdmin && <Button
        className="customer-header-installation-settings-btn"
        color={ 'primary' }
        onClick={ showInstallationSettingsModal }
      >
        { t('Customer:installationSettings') }
      </Button> }

      <InstallationSettingsModal
        isModalOpen={ isModalOpen }
        target={ `button-assign-modal` }
        toggle={ showInstallationSettingsModal }
        customer={ customer }
      />
    </>
  );
});

export default translate(['Common', 'Customer'])(
  InstallationSettings
);