import joi from 'joi';

import { find } from 'lodash';
import { getSupportedCountries } from './countries';
import { ISelectValue } from '../ui/Select/Select';
import { t } from 'i18next';
import { VALID_SIM_ROUTER_REFERENCE } from '../stores/devices_inscription/contants';
import { EnergyDistributor } from '../graphql/graphql.schema';
import { EnergyDistributorName } from '../components/customers/installation/battery/activation/constants';

export const emailValidator = (email?: string): undefined | string => {
  const validation = joi.validate(
    email,
    joi.string().regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/)
  );
  if (validation.error !== null) {
    return t('Common:emailAddressError');
  }
  return undefined;
};

export const eldPdlValidator = (pdlNumber?: string) => {
  const errorEnedis = enedisPdlValidator(pdlNumber);
  const errorstrasbourgElectricity = strasbourgElectricityPdlValidator(
    pdlNumber
  );
  if (errorEnedis && errorstrasbourgElectricity) {
    return t('MySmartBattery:errors.eldFormatInvalid');
  }
  return undefined;
};

export const enedisPdlValidator = (pdlNumber?: string) => {
  const validation = joi.validate(pdlNumber, joi.string().regex(/^[0-9]{14}$/));

  if (validation.error !== null) {
    return t('Installation:invalidPdlFormatForEnedis');
  }
  return undefined;
};

export const strasbourgElectricityPdlValidator = (pdlNumber?: string) => {
  const validation = joi.validate(
    pdlNumber,
    joi.string().regex(/^67\d{3}\/E1\/\d{7} \d{2}$/)
  );

  if (validation.error !== null) {
    return t('Installation:invalidPdlFormatForStrasbourgElectricity');
  }
  return undefined;
};

export const strasbourgInjectionPdlValidator = (injectionPdlNumber?: string) => {
  const validation = joi.validate(
    injectionPdlNumber,
    joi.string().regex(/^\d{5}\/P1\/\d{7}$/)
  );

  if (validation.error !== null) {
    return t('Installation:invalidInjectionPdlFormatForStrasbourgElectricity');
  }
  return undefined;
};

export const pdlValidator = (pdlNumber?: string, withMsb: boolean = false, energyDistributor: EnergyDistributor | null = null) => {
  if (withMsb) {
    if (energyDistributor && energyDistributor.name === EnergyDistributorName.ELECTRICITE_STRASBOURG){
      return strasbourgElectricityPdlValidator(pdlNumber);
    }

    return enedisPdlValidator(pdlNumber);
  }

  const validation = joi.validate(
    pdlNumber,
    joi.string().regex(/^[a-zA-Z0-9 /]{5,19}$/)
  );

  if (validation.error !== null) {
    return t('Installation:invalidPdlFormat');
  }

  return undefined;
};

export const checkIfSerialNumberIsPlaneteOuiFormat = (
  serialNumber?: string
): boolean => {
  const snValidation = joi.validate(
    serialNumber,
    joi
      .string()
      .regex(
        /(^0([0-9]{2})([0|1])([0-9A-F]{2})([0-9]{3})(19|[2-9]{1}\d)((?!0{5})[0-9]{5})$)/
      )
      .max(16)
  );

  return !snValidation.error;
};

export const msbMylightModemSerialNumberValidator = (serialNumber?: string) => {
  const validation = joi.validate(
    serialNumber,
    joi
      .string()
      .regex(/(^\d{10}$)/)
      .max(10)
  );

  if (validation.error !== null) {
    return t('Installation:invalidMsbSnFormat');
  }
  return undefined;
};

export const checkIfSerialNumberIsMyLightModemFormat = (
  serialNumber?: string
): boolean => {
  return !msbMylightModemSerialNumberValidator(serialNumber);
};

export const msbOldMylightSerialNumberValidator = (serialNumber?: string) => {
  const validation = joi.validate(
    serialNumber,
    joi
      .string()
      .regex(
        /(^1([0-9]{2})([0-9])([0-9]{2})([0-9]{3})(19|[2-9]{1}\d)((?!0{5})[0-9]{5})$)/
      )
      .max(16)
  );

  if (validation.error !== null) {
    return t('Installation:invalidMsbSnFormat');
  }
  return undefined;
};

export const checkIfSerialNumberIsMyLightFormat = (
  serialNumber?: string
): boolean => {
  return !msbOldMylightSerialNumberValidator(serialNumber);
};

export const caeNumberValidator = (caeNumber?: string) => {
  const validation = joi.validate(
    caeNumber,
    joi.string().regex(/^[0-9]{10}$/)
  );
  if (validation.error !== null) {
    return t('Installation:invalidCaeFormat');
  }
  return undefined;
};

export const enedisConnectNumberValidator = (enedisConnectNumber?: string) => {
  const validation = joi.validate(
    enedisConnectNumber,
    joi.string().regex(/^[0-9]{4}P4E[0-9]{6}$/)
  );
  if (validation.error !== null) {
    return t('Installation:invalidEnedisConnectNumberFormat');
  }
  return undefined;
};

export const consuelNumberValidator = (consuelNumber?: string) => {
  const validation = joi.validate(
    consuelNumber,
    joi.string().regex(/^[a-zA-Z0-9 ]{14}$/)
  );
  if (validation.error !== null) {
    return t('Installation:invalidConsuelNumberFormat');
  }
  return undefined;
};

export const consuelDeclaredPowerValidator = (
  consuelDeclaredPower?: number
) => {
  const validation = joi.validate(
    consuelDeclaredPower,
    joi
      .number()
      .precision(2)
      .min(0.01)
  );
  if (validation.error !== null) {
    return t('Installation:invalidConsuelDeclaredPowerFormat');
  }
  return undefined;
};

export const connectionPowerValidator = (
  connectionPower?: number
) => {
  const validation = joi.validate(
    connectionPower,
    joi
      .number()
      .precision(2)
      .min(0.01)
  );
  if (validation.error !== null) {
    return t('Installation:invalidConnectionPowerFormat');
  }
  return undefined;
};

export const passwordValidator = (password?: string): undefined | string => {
  const validation = joi.validate(password, joi.string().empty());
  if (validation.error !== null) {
    return t('Login:wrongPassword');
  }
  return undefined;
};

export const newPasswordValidator = (password?: string): undefined | string => {
  const validation = joi.validate(
    password,
    joi
      .string()
      .empty()
      .min(8)
    );
  if (validation.error !== null) {
    return t('ChangePassword:passwordToShort');
  }
  return undefined;
};

export const userNameValidator = (name?: string): undefined | string => {
  const validation = joi.validate(
    name,
    joi
      .string()
      .empty()
      .max(100)
  );
  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};
export const maintainerError = (maintainer?: string): undefined | string => {
  const validation = joi.validate(maintainer, joi.required());
  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};
export const phoneNumberValidator = (
  phoneNumber?: string
): undefined | string => {
  const validation = joi.validate(
    phoneNumber,
    joi
      .string()
      .max(20)
      .regex(/^[+]*[(]?[0-9]{1,4}[)]?[-\s\./0-9]*$/g)
  );
  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};

export const dateValidator = (date?: Date): undefined | string => {
  const validation = joi.validate(date, joi.date());
  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};

export const basicSelectValidator = (selected: any): undefined | string => {
  const schema = joi
    .string()
    .required()
    .disallow(['unknown', 'null']);

  const validation = joi.validate(selected, schema);

  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};

export const numberWithoutZeroValidator = (
  number?: number
): undefined | string => {
  const validation = joi.validate(
    number,
    joi
      .number()
      .required()
      .disallow(0)
  );
  if (validation.error !== null) {
    return t('Errors:numberFormatError');
  }
  return undefined;
};

export const numberValidator = (number?: number): undefined | string => {
  const validation = joi.validate(number, joi.number().empty());
  if (validation.error !== null) {
    return t('Errors:numberFormatError');
  }
  return undefined;
};

export const basicStringValidator = (string?: string): undefined | string => {
  const validation = joi.validate(
    string,
    joi
      .string()
      .empty()
      .max(50)
  );
  if (validation.error !== null) {
    return t('Errors:tooLongStringError');
  }
  return undefined;
};

export const postalCodeValidator = (code?: string): undefined | string => {
  const validation = joi.validate(
    code,
    joi
      .string()
      .empty()
      .min(4)
      .max(5)
      .regex(/^[0-9]{4,5}$/)
  );
  if (validation.error !== null) {
    return 'validation error';
  }
  return undefined;
};

export const geolocationValidtor = (
  latitude?: number,
  longitude?: number
): undefined | string => {
  const validation = joi.validate(latitude, joi.number().invalid(0));
  const validation2 = joi.validate(longitude, joi.number().invalid(0));

  if (validation.error !== null && validation2.error !== null) {
    return 'validation error';
  }
  return undefined;
};

export const countryValidator = (
  country?: ISelectValue
): undefined | string => {
  const isSupported = find(
    getSupportedCountries(),
    item => country && country.value === item.value
  );

  if (typeof isSupported === 'undefined') {
    return t('Errors:countryIsNotSupported');
  }
  return undefined;
};

export const envoySerialNumberValidator = (envoySerialNumber: string) => {
  if (envoySerialNumber.length !== 12) {
    return t('EnphasePartnership:wrongEnvoyFormat');
  }
  return undefined;
};
export const enphaseSystemIdValidator = (enphaseSystemId: string) => {
  if (enphaseSystemId.length < 5 || enphaseSystemId.length > 7) {
    return t('EnphasePartnership:wrongSystemIdFormat');
  }
  return undefined;
};
export const enphaseInstallerIdValidator = (enphaseInstallerId?: number) => {
  if (enphaseInstallerId && enphaseInstallerId < 10) {
    return t('EnphasePartnership:wrongInstallerIdFormat');
  }
};
export const emailConfirmationValidator = (
  email: string,
  confirmation: string
) => {
  if (emailValidator(confirmation)) {
    return emailValidator(confirmation);
  }
  if (confirmation !== email) {
    return t('EnphasePartnership:wrongEmail');
  }
  return undefined;
};

export const simRouterReferenceValidator = (
  simRouterReference: string | undefined
) => {
  if (simRouterReference !== VALID_SIM_ROUTER_REFERENCE) {
    return 'validation error';
  }
  return undefined;
};
