import { action, computed, observable } from 'mobx';
import { MasterTypeInternetConnection } from '../../constants/MasterTypeInternetConnection';
import {
  EElectricInstallation,
  ElectricInstallation, EnergyDistributor
} from '../../graphql/graphql.schema';
import { TimeRange, splitRangesStr } from '../ranges/timeRange';
import { sortBy } from 'lodash';
import {
  numberWithoutZeroValidator,
  basicSelectValidator,
  numberValidator,
  pdlValidator,
  simRouterReferenceValidator
} from '../../utils/validators';
import { t } from 'i18next';
import inscription from '../inscription/inscriptionStore';
import { VALID_SIM_ROUTER_REFERENCE } from '../devices_inscription/contants';
import { WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

const GET_PDL_NUMBER_BY_CUSTOMER_ID = gql`
  query getPdlNumberByCustomerId($customerId: String!) {
    getPdlNumberByCustomerId(customerId: $customerId) {
      pdlNumber
    }
  }
`;

export class EditElectricalInstallationStore {
  @observable public id: string = '';
  @observable public gridType?: string = undefined;
  @observable public rackingPower?: number = undefined;
  @observable public powerContractType?: string = undefined;
  @observable public powerContractOption?: string = undefined;
  @observable public hcRanges: TimeRange[] = [];
  @observable public powerProvider?: string = undefined;
  @observable public sellingPower?: boolean = undefined;
  @observable public sellingEnergyPrice?: number = undefined;
  @observable public internetServiceProvider?: string = undefined;
  @observable public internetBoxModel?: string = undefined;

  @observable public masterTypeInternetConnection?: string = undefined;
  @observable public simRouterReference: string = '';
  @observable public simSerialNumber: string = '';

  @observable public pdlNumber?: string = '';
  @observable public energyDistributor: EnergyDistributor | null = null;
  @observable public mandatoryPdlNumber: boolean = false;

  @observable public canWrite?: boolean = undefined;

  // FIXME: find better way to handle sotre edition
  @observable public edited: boolean = false;

  @action.bound
  public setEnergyDistributor(energyDistributor: EnergyDistributor): void {
    this.energyDistributor = energyDistributor;
  }

  @action.bound
  public setEdited(edited: boolean): void {
    this.edited = edited;
  }

  @action.bound
  public setId(id: string): void {
    this.edited = true;
    this.id = id;
  }

  @action.bound
  public setGridType(gridType: string): void {
    this.edited = true;
    this.gridType = gridType;
  }

  @action.bound
  public setRackingPower(rackingPower: number): void {
    this.edited = true;
    this.rackingPower = rackingPower;
  }

  @action.bound
  public setPowerContractType(
    powerContractType: 'predefined' | 'flat' | 'custom'
  ): void {
    this.edited = true;
    this.powerContractType = powerContractType;
    if (powerContractType !== 'custom') {
      this.setHcRanges([]);
    }
  }

  @action.bound
  public setPowerContractOption(powerContractOption: string): void {
    this.edited = true;
    this.powerContractOption = powerContractOption;
  }

  @action.bound
  public setHcRanges(hcRanges: TimeRange[]): void {
    this.edited = true;
    this.hcRanges = hcRanges;
  }

  @action.bound
  public setPowerProvider(powerProvider: string): void {
    this.edited = true;
    this.powerProvider = powerProvider;
  }

  @action.bound
  public setSellingPower(sellingPower: boolean): void {
    this.edited = true;
    this.sellingPower = sellingPower;
    if (!sellingPower) {
      this.setSellingEnergyPrice(0);
    }
  }

  @action.bound
  public setSellingEnergyPrice(sellingEnergyPrice: number): void {
    this.edited = true;
    this.sellingEnergyPrice = sellingEnergyPrice;
  }

  @action.bound
  public setInternetServiceProvider(internetServiceProvider: string): void {
    this.edited = true;
    this.internetServiceProvider = internetServiceProvider;
  }

  @action.bound
  public setInternetBoxModel(internetBoxModel: string): void {
    this.edited = true;
    this.internetBoxModel = internetBoxModel;
  }

  @action.bound
  public setMasterTypeInternetConnection(
    masterTypeInternetConnection: string
  ): void {
    this.edited = true;
    this.masterTypeInternetConnection = masterTypeInternetConnection;
    if (this.isMasterConnectedViaGSM && !this.isSimRouterReferenceValid) {
      this.setSimRouterReference(VALID_SIM_ROUTER_REFERENCE);
    }
  }

  @action.bound
  public setSimRouterReference(
    simRouterReference: string,
    isFirstInit?: boolean
  ) {
    if (!isFirstInit) {
      this.edited = true;
    }
    if (simRouterReference === VALID_SIM_ROUTER_REFERENCE) {
      this.simRouterReference = simRouterReference;
    } else {
      this.simRouterReference = '';
    }
  }

  @action.bound
  public setSimSerialNumber(simSerialNumber: string) {
    this.edited = true;
    this.simSerialNumber = simSerialNumber;
  }

  @action.bound
  public setPdlNumber(pdlNumber: string): void {
    this.edited = true;
    this.pdlNumber = pdlNumber;
  }
  @action.bound
  public updatePdlMandatory(mandatory: boolean): void {
    this.mandatoryPdlNumber = mandatory;
  }

  @computed
  public get errorPdlNumber(): string | undefined {
    return pdlValidator(this.pdlNumber, this.mandatoryPdlNumber, this.energyDistributor);
  }

  @computed
  public get isValidPdlNumber(): boolean {
    const pdlIsEmpty = this.pdlNumber === '' || !this.pdlNumber;
    if (this.mandatoryPdlNumber) {
      if (pdlIsEmpty) {
        return false;
      }
      return !this.errorPdlNumber;
    }
    if (inscription.customerIsFromCH || pdlIsEmpty) {
      return true;
    }
    return !this.errorPdlNumber;
  }

  @computed
  get isMasterConnectedViaGSM(): boolean {
    return Boolean(
      this.masterTypeInternetConnection === MasterTypeInternetConnection.GSM
    );
  }

  @computed
  public get simSerialNumberError(): string | undefined {
    return this.isMasterConnectedViaGSM
      ? numberValidator(Number(this.simSerialNumber))
      : undefined;
  }

  @computed
  public get simRouterReferenceError(): string | undefined {
    return this.isMasterConnectedViaGSM
      ? simRouterReferenceValidator(this.simRouterReference)
      : undefined;
  }

  @computed
  public get isSimRouterReferenceValid(): boolean {
    return this.isMasterConnectedViaGSM
      ? this.simRouterReferenceError === undefined
      : true;
  }

  @computed
  public get errorPowerContractType(): string | undefined {
    return basicSelectValidator(this.powerContractType);
  }

  @computed
  public get errorPowerContractOption(): string | undefined {
    return this.powerContractType === 'predefined'
      ? basicSelectValidator(this.powerContractOption)
      : undefined;
  }

  @computed
  public get errorGridType(): string | undefined {
    return basicSelectValidator(this.gridType);
  }

  @computed
  public get errorSellingEnergyPrice(): boolean {
    return Boolean(
      this.sellingPower && numberWithoutZeroValidator(this.sellingEnergyPrice)
    );
  }

  @computed
  public get errorRackingPower(): string | undefined {
    return !!numberWithoutZeroValidator(this.rackingPower)
      ? t('Errors:rackingPowerError')
      : undefined;
  }

  @computed
  public get errorSelectRackingPower(): string | undefined {
    return basicSelectValidator(String(this.errorRackingPower));
  }

  @computed
  public get errorPowerProvider(): boolean {
    return !this.powerProvider;
  }

  @computed
  public get errorInternetProvider(): boolean {
    return !this.internetServiceProvider;
  }

  @computed
  get timeSlots(): string {
    return this.hcRanges.map(e => e.getStr).join('');
  }

  @action.bound
  public addRange(): void {
    this.hcRanges.push(new TimeRange());
  }

  @action.bound
  public removeRange(): void {
    this.hcRanges.pop();
  }

  @action.bound
  public setElectricalInstallation({
    id,
    gridType,
    rackingPower,
    powerContractType,
    powerContractOption,
    timeSlots,
    powerProvider,
    sellingPower,
    sellingEnergyPrice,
    internetServiceProvider,
    internetBoxModel,
    masterTypeInternetConnection,
    pdlNumber,
    simSerialNumber,
    simRouterReference
  }: ElectricInstallation): void {
    this.edited = false;
    this.id = id;
    this.gridType = gridType === 'unknown' ? '' : gridType;
    this.rackingPower = rackingPower;
    this.powerContractType =
      powerContractType === 'unknown' ? '' : powerContractType;
    this.powerContractOption = powerContractOption;
    this.powerProvider = powerProvider;
    this.sellingPower = sellingPower;
    this.sellingEnergyPrice = sellingEnergyPrice;
    this.internetServiceProvider = internetServiceProvider;
    this.internetBoxModel = internetBoxModel;
    this.masterTypeInternetConnection = masterTypeInternetConnection;
    this.pdlNumber = pdlNumber;
    this.hcRanges = splitRangesStr(timeSlots).map(e => {
      const tmp = new TimeRange();
      tmp.fromSrsStr(e);
      return tmp;
    });
    this.simSerialNumber = simSerialNumber || '';
    this.setSimRouterReference(simRouterReference || '', true);
  }

  @computed
  get editedElectricalInstallation(): EElectricInstallation {
    const sortedHcRanges = sortBy(this.hcRanges, ['startH']);
    return {
      id: this.id,
      gridType: this.gridType,
      rackingPower: this.rackingPower,
      powerContractType: this.powerContractType,
      timeSlots: sortedHcRanges.map(h => h.getStr).join(';'),
      powerProvider: this.powerProvider,
      sellingPower: this.sellingPower,
      sellingEnergyPrice: this.sellingEnergyPrice,
      internetServiceProvider: this.internetServiceProvider,
      internetBoxModel: this.internetBoxModel,
      masterTypeInternetConnection: this.masterTypeInternetConnection,
      pdlNumber: this.pdlNumber,
      powerContractOption: this.powerContractOption,
      simSerialNumber: this.isMasterConnectedViaGSM ? this.simSerialNumber : '',
      simRouterReference: this.isMasterConnectedViaGSM
        ? this.simRouterReference
        : ''
    };
  }
  @computed
  get getMissingFieldsElectricalInstallation(): string[] | undefined {
    const hcRangesIsMissing = this.hcRangeConflict && t('Customer:timeSlots');
    const pdlIsMissing =
      this.mandatoryPdlNumber &&
      !this.isValidPdlNumber &&
      t('Installation:pdl');
    const powerContractTypeIsMissing =
      this.errorPowerContractType && t('Customer:powerContractType');
    const powerContractOptionIsMissing =
      this.errorPowerContractOption && t('Customer:powerContractOption');
    const gridTypeIsMissing =
      this.errorGridType && t('Customer:gridElectricalPhaseType');
    const rackingPowerIsMissing =
      this.errorRackingPower && t('Customer:rackingPower');
    const sellingEnergyPriceIsMissing =
      this.errorSellingEnergyPrice && t('Customer:sellingEnergyPrice');
    const powerProviderIsMissing =
      this.errorPowerProvider && t('Customer:electricityProvider');
    const internetProviderIsMissing =
      this.errorInternetProvider && t('Customer:internetProvider');
    const simRouterReferenceIsMissing =
      this.simRouterReferenceError && t('Installation:simRouterReference');
    const simSerialNumberIsMissing =
      this.simSerialNumberError && t('Installation:simSerialNumber');
    const missingFields = [
      gridTypeIsMissing,
      rackingPowerIsMissing,
      pdlIsMissing,
      powerProviderIsMissing,
      powerContractTypeIsMissing,
      powerContractOptionIsMissing,
      hcRangesIsMissing,
      sellingEnergyPriceIsMissing,
      internetProviderIsMissing,
      simRouterReferenceIsMissing,
      simSerialNumberIsMissing
    ];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get customerEditFormIsInvalid(): boolean {
    return (
      !this.id ||
      !this.isValidPdlNumber ||
      !!this.errorPowerContractType ||
      !!this.errorPowerContractOption ||
      !!this.errorGridType ||
      !!this.errorRackingPower ||
      !!this.errorSellingEnergyPrice ||
      !!this.errorPowerProvider ||
      !!this.errorInternetProvider ||
      !!this.hcRangeConflict ||
      !!this.simSerialNumberError ||
      !!this.simRouterReferenceError
    );
  }

  @computed
  get customerInscriptionFormIsInvalid(): boolean {
    return (
      !this.id ||
      !this.isValidPdlNumber ||
      !!this.errorGridType ||
      !!this.errorRackingPower ||
      !!this.errorInternetProvider
    );
  }

  @computed
  get hcRangeConflict(): boolean {
    let isOverlapped = 0;
    if (this.hcRanges.length > 1) {
      const oneDayRanges = this.hcRanges
        .filter(range => range.startD === range.endD)
        .sort(
          (a, b) =>
            a.startH > b.startH ||
            (a.startH === b.startH && a.startM >= b.startM)
              ? 1
              : -1
        );
      const twoDaysRanges = this.hcRanges
        .filter(range => range.startD !== range.endD)
        .sort(
          (a, b) =>
            a.startH > b.startH ||
            (a.startH === b.startH && a.startM >= b.startM)
              ? 1
              : -1
        );
      const hcRanges = [...oneDayRanges, ...twoDaysRanges];

      hcRanges.reduce((currentValue, nextValue) => {
        const isOneDayRangeHourConflict =
          currentValue.endH > nextValue.startH ||
          (currentValue.endH === nextValue.startH &&
            currentValue.endM >= nextValue.startM);

        const isTwoDaysRangeEndHourConflict =
          currentValue.startD !== currentValue.endD &&
          nextValue.startD !== nextValue.endD &&
          (currentValue.endH > nextValue.endH ||
            (currentValue.endH === nextValue.endH &&
              currentValue.endM >= nextValue.endM));

        const isTwoDaysRangeStartHourConflict =
          currentValue.startD !== currentValue.endD &&
          (nextValue.startH > currentValue.startH ||
            (nextValue.startH === currentValue.startH &&
              nextValue.startM >= currentValue.startM));

        if (
          isOneDayRangeHourConflict ||
          isTwoDaysRangeEndHourConflict ||
          isTwoDaysRangeStartHourConflict
        ) {
          isOverlapped = +1;
        }

        return nextValue;
      });
    }
    return isOverlapped !== 0;
  }

  @action.bound
  public async getPdlNumberByCustomerId(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      const { data } = await client.query({
        query: GET_PDL_NUMBER_BY_CUSTOMER_ID,
        fetchPolicy: 'no-cache',
        variables: {
          customerId
        }
      });

      return Promise.resolve(data.getPdlNumberByCustomerId);
    } catch (error) {
      return Promise.reject(
        t(`MySmartBattery:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }
}

const editElectricalInstallationStore: EditElectricalInstallationStore = new EditElectricalInstallationStore();
export default editElectricalInstallationStore;
