import React, { useState } from 'react';
import { Button, Form, FormGroup, Col, Alert } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormInput } from './../../ui/FormInput/FormInput';
import LinksContainer from './../../ui/LoginBox/LinksContainer';
import LoginActionWrapper from './../../ui/Wrapper/LoginActionWrapper';

interface IProps {
  t: TranslationFunction;
  Store?: any;
  loading?: boolean;
  onSubmit: (newPassword: string) => void;
  called?: boolean;
  success?: boolean;
  valid?: boolean;
}

const ChangePasswordForm = inject('Store')(
  observer(
    ({
      t,
      Store: {
        changePasswordForm: {
          newPasswordField,
          repeatedNewPasswordField,
          newPasswordError,
          repeatedNewPasswordError,
          changePasswordFormIsInvalid,
          updateNewPassword,
          updateRepeatedNewPassword,
        }
      },
      onSubmit,
      loading,
      called,
      success,
      valid
    }: IProps) => {
      const [arePasswordsIdentical, setArePasswordsIdentical] = useState<boolean>(false);

      const onChangeNewPassword = (input: string) => {
        updateNewPassword(input);

        repeatedNewPasswordField && comparePasswords(repeatedNewPasswordField, input);
      };

      const onChangeRepeatedNewPassword = (input: string) => {
        updateRepeatedNewPassword(input);

        newPasswordField && comparePasswords(newPasswordField, input);
      };

      const comparePasswords = (password: string, secondPassword: string) => {
        setArePasswordsIdentical(password === secondPassword);
      };

      return (
        <Form
          className={'change-password-form'}
          onSubmit={event => {
            event.preventDefault();
            if (!loading) {
              onSubmit(newPasswordField);
            }
          }}
        >
          { !loading && valid && !success && (
            <>
              <Col mx="2" xs="12">
                <p className={'change-password-description'}>
                  { t('changePasswordDescription') }
                </p>
              </Col>

              <Col mx="2" xs="12">
                <FormGroup>
                  <FormInput
                    value={ newPasswordField }
                    type="password"
                    name={ t('newPasswordLabel') }
                    id="new-password-input"
                    onChange={ onChangeNewPassword }
                    error={ newPasswordField && newPasswordError }
                  />
                </FormGroup>
              </Col>

              <Col mx="2" xs="12">
                <FormGroup>
                  <FormInput
                    value={ repeatedNewPasswordField }
                    type="password"
                    name={ t('repeatedNewPasswordLabel') }
                    id="repeated-new-password-input"
                    onChange={ onChangeRepeatedNewPassword }
                    error={ repeatedNewPasswordField && repeatedNewPasswordError }
                  />
                </FormGroup>
              </Col>

              { newPasswordField && repeatedNewPasswordField && !arePasswordsIdentical && (
                <Col>
                  <Alert className="change-password-warning" color="warning">
                    { t('passwordsAreNotTheSame') }
                  </Alert>
                </Col>
              )}
            </>
          )}

          {!loading && !valid && (
            <Col mx="2" xs="12">
              <Alert className="change-password-error" color="danger">
                { t('invalidRecoveryCodeError') }
              </Alert>
            </Col>
          )}

          {!loading &&
            called &&
            success && (
              <Col mx="2" xs="12">
                <Alert className="change-password-success" color="success">
                  { t('passwordChangedSuccessfully') }
                </Alert>
              </Col>
          )}

          { valid && !success && (
            <LoginActionWrapper>
              <Button
                type="submit"
                color="primary"
                disabled={ !arePasswordsIdentical || changePasswordFormIsInvalid }
              >
                { t('saveButton') }
              </Button>
            </LoginActionWrapper>
          )}
          
          <LinksContainer>
            <Link to="/login">{t('Common:goBackLink')}</Link>
          </LinksContainer>
        </Form>
      );
    }
  )
);

export default translate(['ChangePassword', 'Common'])(ChangePasswordForm);