import { EPVInstallation, PVInstallation } from '../../graphql/graphql.schema';
import { observable, action, computed } from 'mobx';
import { t } from 'i18next';
import { WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

const ASSIGN_AN_EMPTY_INSTALLATION = gql`
  mutation assignAnEmptyInstallation(
    $customerId: String!
  ) {
    assignAnEmptyInstallation(
      customerId: $customerId
    ) {
      id
      pvInstallations {
        id
      }
    }
  }
`;

const UNLINK_INSTALLATION_FROM_CUSTOMER = gql`
  mutation unlinkInstallationFromCustomer(
    $customerId: String!
  ) {
    unlinkInstallationFromCustomer(
      customerId: $customerId
    ) {
      id
    }
  }
`;

const GET_INSTALLATION_DETAILS_BY_PDL = gql`
  query getInstallationDetailsByPdl(
    $pdlNumber: String!
  ) {
    getInstallationDetailsByPdl(
      pdlNumber: $pdlNumber
    ) {
      id
      electricInstallations{
        pdlNumber
      }
      customers{
        id
        firstName
        lastName
      }
    }
  }
`;

const ASSIGN_INSTALLATION_TO_CUSTOMER_BY_PDL = gql`
  mutation assignInstallationToCustomerByPdl(
    $customerId: String!
    $pdlNumber: String!
  ) {
    assignInstallationToCustomerByPdl(
      customerId: $customerId
      pdlNumber: $pdlNumber
    ) {
      id
    }
  }
`;

export class EditPVInstallationStore implements Partial<EPVInstallation> {
  @observable public currency?: string = undefined;
  @observable public id: string = '';
  @observable public ucgSerialNumber?: string = undefined;
  @observable public panelCount?: number = undefined;
  @observable public panelManufacturer?: string = undefined;
  @observable public panelWattPeak?: number = undefined;
  @observable public srsLicenceStatus?: string = undefined;
  @observable public srsRegistrationStatus?: string = undefined;
  @observable public edited: boolean = false;
  @observable public hasMultipleProductionCounter?: boolean = undefined;

  @action.bound
  public setEdited(edited: boolean) {
    this.edited = edited;
  }

  @action.bound
  public setPanelCount(panelCount: number) {
    this.edited = true;
    this.panelCount = panelCount;
  }

  @action.bound
  public setPanelManufacturer(panelManufacturer: string) {
    this.edited = true;
    this.panelManufacturer = panelManufacturer;
  }

  @action.bound
  public setPanelWattPeak(panelWattPeak: number) {
    this.edited = true;
    this.panelWattPeak = panelWattPeak;
  }

  @action.bound
  public setPvInstallation(e: PVInstallation) {
    this.edited = false;
    this.currency = e.currency;
    this.id = e.id;
    this.ucgSerialNumber = e.ucgSerialNumber;
    this.panelCount = e.panelCount === 0 ? undefined : e.panelCount;
    this.panelManufacturer = e.panelManufacturer;
    this.panelWattPeak = e.panelWattPeak === 0 ? undefined : e.panelWattPeak;
    this.srsLicenceStatus = e.srsLicenceStatus;
    this.srsRegistrationStatus = e.srsRegistrationStatus;
    this.hasMultipleProductionCounter = e.hasMultipleProductionCounter;
  }

  @computed
  get editedPVInstallation(): EPVInstallation {
    return {
      id: this.id,
      currency: this.currency,
      panelCount: this.panelCount,
      panelWattPeak: this.panelWattPeak,
      panelManufacturer: this.panelManufacturer,
      srsLicenceStatus: this.srsLicenceStatus,
      srsRegistrationStatus: this.srsRegistrationStatus,
      ucgSerialNumber: this.ucgSerialNumber
    };
  }

  @computed
  public get errorPanelManufacturer(): boolean {
    return !this.panelManufacturer;
  }

  @computed
  public get errorPanelCount(): boolean {
    return this.panelCount === 0;
  }

  @computed
  public get errorPanelWattPeak(): boolean {
    return this.panelWattPeak === 0;
  }

  @computed
  get getMissingFieldsPVInstallation(): string[] | undefined {
    const panelManufacturerIsMissing =
      this.errorPanelManufacturer && t('Customer:panelManufacturer');
    const missingFields = [panelManufacturerIsMissing];
    if (missingFields.some(field => !!field)) {
      return missingFields;
    }
    return undefined;
  }

  @computed
  get customerEditFormIsInvalid(): boolean {
    return (
      !this.id ||
      !!this.errorPanelManufacturer ||
      this.errorPanelCount ||
      this.errorPanelWattPeak
    );
  }
  @computed
  get customerInscriptionFormIsInvalid(): boolean {
    return (
      !this.id ||
      !!this.errorPanelManufacturer ||
      !this.panelCount ||
      !this.panelWattPeak ||
      this.errorPanelCount ||
      this.errorPanelWattPeak
    );
  }

  @computed
  get isDone(): boolean {
    return !!(this.panelWattPeak && this.panelCount);
  }

  @action.bound
  public async assignAnEmptyInstallation(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      await client.mutate({
        mutation: ASSIGN_AN_EMPTY_INSTALLATION,
        variables: {
          customerId,
        }
      });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        t(`Installation:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async unlinkInstallationFromCustomer(
    client: WithApolloClient<any>,
    customerId: string
  ) {
    try {
      const { data } = await client.mutate({
        mutation: UNLINK_INSTALLATION_FROM_CUSTOMER,
        variables: {
          customerId,
        }
      });

      return Promise.resolve(data.unlinkInstallationFromCustomer);
    } catch (error) {
      return Promise.reject(
        t(`Installation:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async getInstallationDetailsByPdl(
    client: WithApolloClient<any>,
    pdlNumber: string,
  ) {
    try {
      const { data } = await client.query({
        query: GET_INSTALLATION_DETAILS_BY_PDL,
        fetchPolicy: 'no-cache',
        variables: {
          pdlNumber
        }
      });

      return data;
    } catch (error) {
      return Promise.reject(
        t(`Installation:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }

  @action.bound
  public async assignInstallationToCustomerByPdl(
    client: WithApolloClient<any>,
    customerId: string,
    pdlNumber: string,
  ) {
    try {
      await client.mutate({
        mutation: ASSIGN_INSTALLATION_TO_CUSTOMER_BY_PDL,
        variables: {
          customerId,
          pdlNumber,
        }
      });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        t(`Installation:errors.${error.graphQLErrors[0].message.errorCode}`)
      );
    }
  }
}

const editPVInstallationStore: EditPVInstallationStore = new EditPVInstallationStore();
export default editPVInstallationStore;
