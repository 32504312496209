import React from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { withApollo, WithApolloClient, Mutation } from 'react-apollo';
import {
  Button,
  Col,
  Container,
  Row 
} from 'reactstrap';
import gql from 'graphql-tag';
import { safeMobxInject } from './../../../../stores/storeInjectionHelpers';
import { ISnackBarParams } from './../../../../stores/userFeedback/snackbarStore';
import {
  ActivationMySmartBatteryMylight
} from './../../../../stores/my_smart_battery/activationMySmartBatteryMylight';
import {
  EditElectricalInstallationStore
} from './../../../../stores/installations/editElectricalInstallationStore';

const REMOVE_PDL_FROM_INSTALLATION = gql`
  mutation removePdlFromInstallation(
    $customerId: String!
  ) {
    removePdlFromInstallation(
      customerId: $customerId
    ) {
      id
    }
  }
`;

interface IMutationParameters {
  variables: { 
    customerId: string,
  };
}

interface IProps {
  t: TranslationFunction;
  customerId: string;
  toggle: any;
}

interface IStoresProps {
  msb: ActivationMySmartBatteryMylight;
  electricalInstallation: EditElectricalInstallationStore;
  showSnackBar: (params: ISnackBarParams) => void;
}

const InstallationUnlinkPdlModalSection = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    msb: stores.activationMySmartBatteryMylight,
    electricalInstallation: stores.editElectricalInstallation,
    showSnackBar: stores.snackBar.showSnackBar,
  }),
  ({ 
  t,
  customerId,
  toggle,
  msb,
  electricalInstallation,
  showSnackBar,
 }: WithApolloClient<IProps & IStoresProps>) => {
  const onUnlinkPdlButtonClick = (
    removePdlFromInstallation: (mutationParameters: IMutationParameters) => void
  ) => {
    removePdlFromInstallation({
      variables: {
        customerId,
      }
    });
  };

  return (
    <Mutation
      mutation={ REMOVE_PDL_FROM_INSTALLATION }
      onCompleted={() => {
        showSnackBar({ text: t('Customer:unlinkPdlSuccess'), type: 'success' });

        msb.pdlNumber = '';
        electricalInstallation.pdlNumber = '';

        toggle();
      }}
      onError={ () => {
        showSnackBar({ text: t('Customer:unlinkPdlError'), type: 'error' });
      }}
    >
      {(removePdlFromInstallation, { loading }) => {
      return (
        <Container>
          <Row>
            <Col xs={12} className="font-weight-bold">
              <p>{ t('Customer:unlinkPdl') }</p>
            </Col>

            <Col xs={12} className="">
              <p>
                { t('Customer:unlinkPdlDescription') }
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="text-right">
              <Button
                id={ `button-assign-pdl` }
                loading={ loading.toString() }
                className={ 'btn-primary' }
                color="danger"
                onClick={ () => onUnlinkPdlButtonClick(removePdlFromInstallation) }
                >
                { t('Customer:unlinkPdlButton') }
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }}
    </Mutation>
  );
});

export default translate(['Common', 'Customer'])(
  withApollo(InstallationUnlinkPdlModalSection)
);