import { observable, action, computed } from 'mobx';
import { newPasswordValidator } from './../../../utils/validators';

export default class ChangePasswordForm {
  @observable public newPasswordField: string = '';
  @observable public repeatedNewPasswordField: string = '';

  @action.bound
  public updateNewPassword(input: string) {
    this.newPasswordField = input;
  }

  @action.bound
  public updateRepeatedNewPassword(input: string) {
    this.repeatedNewPasswordField = input;
  }

  @computed
  get newPasswordError() {
    return newPasswordValidator(this.newPasswordField);
  }

  @computed
  get repeatedNewPasswordError() {
    return newPasswordValidator(this.repeatedNewPasswordField);
  }

  @computed
  get changePasswordFormIsInvalid() {
    return Boolean(this.newPasswordError || this.repeatedNewPasswordError);
  }
}
