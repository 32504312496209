import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { withApollo, WithApolloClient } from 'react-apollo';
import {
  Button,
  Col,
  Container,
  Row 
} from 'reactstrap';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import editPVInstallationStore, { EditPVInstallationStore } from '../../../../stores/installations/editPVInstallationStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISnackBarParams } from './../../../../stores/userFeedback/snackbarStore';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  customerId: string;
  toggle: any;
}

interface IStoresProps {
  editPVInstallationStore: EditPVInstallationStore,
  showSnackBar: (params: ISnackBarParams) => void;
}

const InstallationUnlinkModalSection = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    editPVInstallationStore: stores.editPVInstallation,
    showSnackBar: stores.snackBar.showSnackBar,
  }),
  ({ 
  t,
  client,
  customerId,
  toggle,
  showSnackBar,
 }: WithApolloClient<IProps & IStoresProps>) => {
  const unlinkInstallationFromCustomer = async () => {
    const response = await editPVInstallationStore.unlinkInstallationFromCustomer(client, customerId);

    if (response) {
      showSnackBar({ text: t('Customer:unlinkInstallationSuccess'), type: 'success' });

      toggle();
    }
  };
  return (
    <Container>
      <Row>
        <Col xs={12} className="font-weight-bold">
          <p>{ t('Customer:unlinkInstallation') }</p>
        </Col>

        <Col xs={12} className="">
          <p>
            { t('Customer:unlinkInstallationDescription') }
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="text-right">
          <Button
              id={ `button-unlink-installation` }
              className={ 'btn-primary' }
              color="primary"
              onClick={ unlinkInstallationFromCustomer }
              >
              { t('Customer:unlinkInstallationButton') }
          </Button>
        </Col>
      </Row>
    </Container>
  );
});

export default withRouter(translate(['Common', 'Installation', 'Customer'])(
  withApollo(InstallationUnlinkModalSection)
));