import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { withApollo, WithApolloClient } from 'react-apollo';
import {
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { safeMobxInject } from './../../../../stores/storeInjectionHelpers';
import { EditElectricalInstallationStore } from './../../../../stores/installations/editElectricalInstallationStore';
import { RouteComponentProps, withRouter } from 'react-router';
import InstallationAssignmentModalSection from './InstallationAssignmentModalSection';
import InstallationUnlinkModalSection from './InstallationUnlinkModalSection';
import { shouldDisplayContinueInstallation } from './../../../../utils/tools';
import InstallationSetPdlModalSection from './InstallationSetPdlModalSection';
import InstallationUnlinkPdlModalSection from './InstallationUnlinkPdlModalSection';
import { ISnackBarParams } from './../../../../stores/userFeedback/snackbarStore';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  isModalOpen: boolean;
  target: string | HTMLElement;
  toggle: any;
  customer: any;
}

interface IStoresProps {
  electricalInstallation: EditElectricalInstallationStore,
  showSnackBar: (params: ISnackBarParams) => void,
}

const InstallationSettingsModal = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    electricalInstallation: stores.editElectricalInstallation,
    showSnackBar: stores.snackBar.showSnackBar,
  }),
  ({ 
  t,
  client,
  isModalOpen, 
  target,
  toggle,
  customer,
  electricalInstallation,
  showSnackBar,
 }: WithApolloClient<IProps & IStoresProps>) => {
  const [pdlNumber, setPdlNumber] = useState<string>('');

  const refreshView = () => {
    window.location.reload();
    toggle();
  };

  useEffect(() => {
    if (isModalOpen) {
      const response = electricalInstallation.getPdlNumberByCustomerId(client, customer.id);

      response
        .then((result: { pdlNumber: string }) => {
          setPdlNumber(result.pdlNumber);
        })
        .catch(() => {
          showSnackBar({ text: t('Customer:installationSettingsCantGetPDL'), type: 'error' });
        });
    }
  }, [isModalOpen]);

  return (
    <Modal
      centered
      isOpen={ isModalOpen }
      target={ target }
      className={ 'battery-document-modal' }
      size='md'
    >
      <ModalHeader toggle={ toggle }>
        { t('Customer:installationSettings') }
      </ModalHeader>

      <ModalBody>
      { shouldDisplayContinueInstallation(customer.status) ? (
        <InstallationAssignmentModalSection customerId={ customer.id } />
      ) : (
        <>
          { pdlNumber && 
          <>
            <InstallationUnlinkPdlModalSection
              customerId={ customer.id }
              toggle={ refreshView }
            />
            <hr/>
            <InstallationUnlinkModalSection
              customerId={ customer.id }
              toggle={ refreshView }
            />
          </> }

          { !pdlNumber && <InstallationSetPdlModalSection
            customerId={ customer.id }
            toggle={ refreshView }
          /> }
        </>
      ) }
      </ModalBody>
    </Modal>
  );
});

export default withRouter(translate(['Common', 'Customer'])(
  withApollo(InstallationSettingsModal)
));