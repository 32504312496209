import { GridType } from '@mylight/data-model';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';

export default {
  MonthNames: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  InscriptionAlerts: {
    modalTitle: 'Attention !',
    ALERT_GRID_TYPE_DIFFERENT_UCG_MODEL:
      'The UCG entered does not correspond to the type of network selected',
    ALERT_NUMBER_GMD_HIGHER_UCG_MODEL: 'Too much GMD for the UCG informed',
    ALERT_NUMBER_GMD_LOWER_UCG_MODEL: 'Not enough GMD for the UCG entered',
    ALERT_NUMBER_TPH_HIGHER_UCG_MODEL: 'Too much TPH for the UCG informed',
    ALERT_NUMBER_TPH_LOWER_UCG_MODEL: 'Not enough TPH for the UCG informed'
  },
  RegistrationStatus: {
    [RegistrationStatus.SETUP_OK]: 'User account not active',
    [RegistrationStatus.USER_SETTINGS_SET]: 'User account not active',
    [RegistrationStatus.USER_PASSWORD_SET]: 'User account not active',
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: 'User account not active',
    [RegistrationStatus.OK]: 'Completed',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]: 'In progress',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]: 'In progress',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]: 'In progress',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]: 'In progress',
    [RegistrationStatus.SETUP_CONNECT_TADO]: 'In progress',
    [RegistrationStatus.SETUP_CHECK_WIRING]: 'In progress',
    [RegistrationStatus.SETUP_USER_REGISTERED]: 'In progress'
  },
  RegistrationStatusToolTips: {
    [RegistrationStatus.SETUP_OK]: `Device registration completed.<br />The user must activate his account.<br />You can resend the activation email via the user <a href="{{link}}">mylight150</a> page`,
    [RegistrationStatus.USER_SETTINGS_SET]: `Device registration completed.<br />The user must activate his account.<br />You can resend the activation email via the user <a href="{{link}}">mylight150</a> page`,
    [RegistrationStatus.USER_PASSWORD_SET]: `Device registration completed.<br />The user must activate his account.<br />You can resend the activation email via the user <a href="{{link}}">mylight150</a> page`,
    [RegistrationStatus.USER_ACCEPTED_LICENCE]: `Device registration completed.<br />The user must activate his account.<br />You can resend the activation email via the user <a href="{{link}}">mylight150</a> page`,
    [RegistrationStatus.OK]: 'The user can access his application',
    [RegistrationStatus.SETUP_SELECTED_PRODUCTION_DEVICE]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_INSTALLATION_DETAILS_SET2]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_DEVICES_REGISTERED]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_CONNECT_TADO]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_CHECK_WIRING]:
      'Device registration not completed',
    [RegistrationStatus.SETUP_USER_REGISTERED]:
      'Device registration not completed'
  },
  Customer: {
    [GridType.SINGLE_PHASE]: 'Mono phased',
    [GridType.THREE_PHASE]: 'Tri phased',
    accountCreationDate: 'Account creation date',
    restrictedAccess: `The customer does not authorize access to his installation data and his consumption/production data.`,
    addACustomer: 'Add a customer',
    address: 'Address',
    birthDate: 'Birth date',
    city: 'City',
    companyName: 'Company',
    converterType: 'Converter type',
    customers: 'Customers',
    device: 'Device',
    deviceMac: 'MAC',
    controlledDeviceName: 'Name',
    devicePower: 'Power',
    devicesList: 'Devices list',
    deviceStatus: 'State',
    deviceType: 'Type',
    deviceTypeName: 'Device type',
    disabled: 'Disabled',
    electricityContract: 'Electricity contract',
    electricityProvider: 'Electricity supplier',
    email: 'Email',
    emailAlert: 'Sending alerts by e-mail to the address provided?',
    enabled: 'Enabled',
    fillMissingFields: `To create a user please fill in the following fields:`,
    globalConsumption: 'Total consumption',
    gridElectricalPhaseType: 'Installation type',
    internetProvider: 'Internet provider',
    internetBoxModel: 'Type of internet box',
    internetServiceProvider: 'Internet service provider',
    installation: 'Installation',
    installer: 'Installer',
    installerLoadingError: 'Error loading installers',
    lastConnection: 'Last connection',
    ignored: 'Ignored',
    lastConnections: 'Last connections',
    lastRegisteredCustomers: 'Last registered customers',
    firstName: 'First Name',
    licence: 'Licence',
    loginAs: 'Customer interface',
    maintainer: 'Maintainer',
    map: 'Carte',
    masterActivationSerialNumber: "Numéro d'activation de la Master",
    mobile: 'Mobile',
    name: 'Last Name',
    gridConsumption: 'Grid consumption',
    no: 'No',
    panelCount: 'Module number',
    panelInstallation: 'Photovoltaic installation',
    panelTotalPower: 'Total installed power',
    panelManufacturer: 'Panel manufacturer',
    performanceRecords: 'Performance reports',
    phone: 'Phone',
    powerContractType: 'Type of electricity contract',
    powerContractOption: 'Other electricity contract',
    postalCode: 'Postal code',
    production: 'Production',
    rackingPower: 'Racking power',
    registrationDate: 'Date of commissioning',
    registrationStatus: 'Registration status',
    resendActivationEmail: 'Send',
    resendActivationEmailSuccess:
      'The activation email has been successfully returned.',
    resendActivationEmailFailure: 'Activation email could not be sent',
    resendActivationEmailAlreadyActivated: 'This user is already active.',
    saleOfSurplus: 'Sale of surplus electricity',
    sellingEnergyPrice: 'Selling price',
    smsAlert: 'Receive alerts by SMS',
    street: 'Street',
    tenant: 'Tenant',
    chooseTenant: 'Choose Tenant',
    timeSlots: 'Time slots',
    wattPeakByPanel: 'Unit power of modules',
    yes: 'Yes',
    alerts: 'Alerts',
    newSubscription: 'NEW CUSTOMER',
    inscription: 'REGISTRATION',
    inscriptionFull: 'MATERIAL REGISTRATION',
    continueInscription: `CONTINUE REGISTRATION`,
    pendingCustomerEmptyList: 'No customers waiting',
    LastRegisteredCustomerEmptyList: 'No last customers',
    boxSerialNumber: 'Box serial number',
    productionAndConsumption: 'Instant production and consumption',
    on: 'CONNECTED',
    offline: 'DISCONNECTED',
    off: 'OFF',
    devices: 'Devices list',
    system: 'System',
    customerEmptyList: 'No customer',
    emptyDeviceList: 'No device',
    account: 'Account',
    customer: 'Customer',
    services: 'Services',
    contact: 'Contact',
    identity: 'Identity',
    country: 'Country',
    OTHER: 'Other',
    ENPHASE: 'Enphase',
    pendingCustomers: 'Customers being installed',
    pendingActivationCustomers: 'Account awaiting activation',
    resendActivationEmailLabel: 'Resend activation email',
    msbStatus_no_msb: 'No',
    msbStatus_pending: 'In progress',
    msbStatus_finished: 'Active',
    searchAddress: 'Address search',
    longitude: 'Longitude',
    latitude: 'Latitude',
    canMoveCursorPositionInstallation:
      'You can refine the position of the installation by moving the cursor on the map',
    newAddressSearch: 'New address search',
    goToCustomer: 'Access the customer',
    showAllCustomers: `See all customers`,
    installationSettings: 'Installation Settings',
    installationSettingsCantGetPDL: "Can't get customer PDL",
    assignInstallation: 'Assign existing installation',
    assignInstallationDescription: 'Enter a valid PDL number to assign existing installation (linked to this PDL) to this customer.',
    assignInstallationValidInstall: 'Valid installation',
    assignInstallationOwner: 'Owner',
    assignInstallationCheckPdl: 'Check PDL',
    assignInstallationAssignAndEdit: 'Assign and edit',
    assignInstallationReadyForTransfer: 'Installation ready for transfer',
    unlinkInstallation: 'Unlink installation',
    unlinkInstallationDescription: 
    'By unlinking the installation, it will be detached from the customer in MyProPortal and its virtual battery will be deleted. After that, the customer could have another installation (in a new home, for example) with a new virtual battery.',
    unlinkInstallationSuccess: 'Installation unlinked successfully',
    unlinkInstallationButton: 'Unlink Installation',
    setPdl: 'Change contract type - add PDL and synchronize',
    setPdlDescription: 'Add the PDL number. The synchronisation between contract and installation will be made automatically.',
    setPdlSuccess: 'PDL assigned successfully',
    setPdlError: 'PDL already linked or invalid format',
    setPdlButton: 'Assign PDL number',
    unlinkPdl: 'Change contract type - delete PDL',
    unlinkPdlDescription: 'Remove the PDL from customer installation to be able to change the contract type.',
    unlinkPdlSuccess: 'PDL removed successfully',
    unlinkPdlError: "Can't remove PDL",
    unlinkPdlButton: 'Remove PDL',
  },
  ContractName: {
    flat: 'Basique',
    custom: 'Peak/Off hours'
  },
  Login: {
    connection: 'Log in',
    email: 'Email',
    error: 'Invalid email or password',
    password: 'Password',
    wrongPassword: 'Password field cannot be empty',
    accountCreationLink: 'Account creation request'
  },
  SubMenu: {
    identity: 'Identity',
    contactPro: 'Pro contacts',
    installation: 'Installation',
    system: 'mylight150',
    support: 'After sales',
    service: 'Services'
  },
  Menu: {
    dashboard: 'Dashboard',
    customers: 'Customers',
    mySmartBattery: 'My Smart Battery',
    company: 'Company',
    tools: 'Tools',
    support: 'Support',
    myAccount: 'My Account',
    proPortal: 'Pro Portal',
    proSizer: 'My Prosizer',
    disconnect: 'Disconnect'
  },
  Support: {
    coordinates: 'Our coordinates:',
    email: 'Email',
    franceCustomerSupportNumber: '0 800 710 226',
    franceTechnicalSupportNumber: '04 69 84 42 94',
    france: 'French phone number',
    switzerland: 'Switzerland phone number',
    switzerlandCustomerSupportNumber: '+41 58 255 11 71',
    switzerlandTechnicalSupportNumber: '+41 58 255 11 71',
    supportCustomerEmail: 'support@mylight150.com',
    supportTechnicalEmail: 'support-pro@mylight150.com',
    technicalTitle: 'Professional technical support',
    customerTitle: 'CVCustomers technical support',
    subscriptionPending: 'subscription Pending',
    subscriptionDone: 'The account is activated.',
    devicesNotInstalled: 'devices not installed',
    loginAsLabel: 'Display MYL2.0',
    workingHours: '9h00 - 12h30 and 13h30 - 18h00\nFrom monday to friday',
    pairingToolkit: 'Peering tool',
    access: `Access the tool`,
    documentationTitle: 'Documentation',
    technicalDocumentation: 'Technical documentation',
    gettingStartedGuide: 'Getting started guide',
    faq: 'FAQ'
  },

  Common: {
    addressNotFound: 'Address not found',
    email: 'Email',
    emailAddress: `Email Address`,
    support: 'Support',
    MAINTAINER: 'Maintainer',
    ADMINISTRATOR: 'Administrator',
    type: 'Type',
    INSTALLER: 'Installer',
    goBackLink: 'Return to the home screen',
    from: 'From',
    to: 'To',
    sure: 'Are you sure?',
    zipCode: 'Postal Code',
    country: 'Country',
    city: 'City',
    address: 'Address',
    additionalAddress: 'Address complement',
    select: 'Select...',
    selectError: 'The selected value is incorrect',
    save: 'Save',
    connect: 'Connect',
    checkAddress: 'Check the Address',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    add: 'Add',
    remove: 'remove',
    associate: 'Associate',
    next: 'Next',
    choseAddress: 'Choose an Address',
    addressError: 'Invalid Address',
    emailTaken: 'This email is already in use',

    tenantError: 'Tenant mandatory',
    maintainerError: 'Maintainer mandatory',
    zipCodeError: 'Invalid format',
    cityError: 'Invalid format',
    countryError: 'Invalid format',

    firstNameError: 'Invalid first name',
    lastNameError: 'Invalid last name',
    phoneError: 'Phone number required',
    emailAddressError: 'Invalid email format',
    birthDateError: 'Invalid date, expected format:',

    createCustomerTitle: 'Add a customer',
    geoLocation: 'Geolocation',

    afterCreateModalTitle: 'The client has been created',
    phoneRequired: 'Telephones * (Please fill in at least one telephone)',

    goToFinishInstallation: 'Register devices',
    goToIdentity: 'See the customer file',
    tip: 'Tip!',
    plus: 'More',
    minus: 'Less',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    redirecting: 'Redirection ...',
    warning: 'Attention',
    loadError: 'Error loading data',
    retry: 'Retry',
    agree: `I agree`,
    invalid: 'Invalid',
    disconnect: 'Disconnect',
    listFilter_status_no: 'User account not active',
    listFilter_status_pending: 'In progress',
    listFilter_status_ok: 'Completed',
    listFilter_msbStatus_no: 'No',
    listFilter_msbStatus_pending: 'In progress',
    listFilter_msbStatus_ok: 'Active',
    loading: 'Loading...',
    noInstallation: `Registration is not completed`,
    none_result: 'No result',
    send: 'Send',
    chooseFile: 'Choose a file',
    close: 'Close',
    new: 'New!',
    fetchMore: 'More',
    noMoreResults: 'No more result',
    or: 'or',
    step: 'Step',
    nextStep: 'Next step',
    prevStep: 'Previous step',
    unknown: 'Unknown'
  },
  Installation: {
    [GridType.SINGLE_PHASE]: 'Single phase',
    [GridType.THREE_PHASE]: 'Three phase',
    systemOffline: 'Installation disconnected',
    saveInstallation: 'save the installation',
    pdl: 'Delivery point number',
    pdlWithMsbNotEditable:
      'The delivery point number is not editable because a My Smart Battery product is registered and active for this client.',
    pdlWithMsbInfo:
      'An Enedis delivery point number is required because a My Smart Battery product is registered for this client',
    pdlForMsbInfo: 'An Enedis delivery point number is required',
    pdlIsNotUnique: 'This delivery point is already associated to a customer',
    invalidPdlFormat:
      'The delivery point number must contain between 5 and 18 characters',
    invalidPdlFormatForEnedis:
      'The Enedis delivery point number must contain 14 numbers',
    invalidPdlFormatForStrasbourgElectricity:
      'The Strasbourg Electricity delivery point number must be in the format XXXXX/E1/XXXXXXX XX',
    invalidInjectionPdlFormatForStrasbourgElectricity:
      'The injection PDL is in the following format: XXXXX/P1/XXXXXXX and must contain 16 characters',
    invalidMsbSnFormat: 'The format of the serial number is incorrect',
    invalidCaeFormat: 'The CAE number must contain 10 numbers',
    invalidEnedisConnectNumberFormat:
      'The Enedis Connect number must follow this pattern: XXXXP4EXXXXXX and must contain 13 characters',
    invalidConsuelDeclaredPowerFormat:
      'The declared power must be a value greater than 0',
    invalidConnectionPowerFormat:
      'The connection power must be a value greater than 0',
    singlePhaseInvalidConnectionPowerInjectionPower:
      'The maximum power that the single phase installation can inject into the network is limited to 6 kVA',
    threePhaseInvalidConnectionPowerInjectionPower:
      'The maximum power that the three phase installation can inject into the network is limited to 36 kVA',
    invalidConnectionPowerInjectionPowerHeader: 'Invalid connection power value',
    tooHighConnectionPowerInjectionPower: 'The connection power cannot exceed the declared installed power (Consuel)',
    invalidStrasbourgInjectionPdlNumberFormat: '',
    invalidConsuelNumberFormat: 'The Consuel number must contain 14 characters',
    start: 'From:',
    end: 'To:',
    startComeBeforeEndWarning: 'The end time must be after the Start time',
    hcRangeOverlapWarning: 'Periods should not overlap',
    masterTypeInternetConnection:
      'How is the Master connected to the internet?',
    simRouterReference: `Router reference`,
    simSerialNumber: `Serial number of the SIM card`,
    ethernetConnectionType: 'Direct connection (with Ethernet cable)',
    wifiConnectionType: 'Connection via a wifi repeater',
    plc_mylightConnectionType: 'Connection via a mylight150 PLC socket (COM 1)',
    plc_otherConnectionType: 'Connection via a PLC socket of another brand',
    gsmConnectionType: 'Connection via cellular modem (GSM router)',
    sellingPower: 'Sale of surplus',
    errorSellingPower: `The energy price is incorrect`,
    unableToSellPowerWithMSB: `The sale of electricity is not possible when the user has a My Smart Battery`,
    errorPanelCount: `The number of modules must be greater than 0`,
    errorPanelWattPeak: `The unit power must be greater than 0`,
    warningResetFunctionCompositeDevice: `Attention, changing the type of installation may cause the resetting of the functions of some already configured devices`,
    areYouSureToChangedGridType: `Are you sure you have selected the correct type of installation, this action can reset the functions of some already configured devices. Please check the configuration of the functions of your devices before finalizing the registration.`,
    france: 'France',
    swiss: 'Switzerland',
    errors: {
      pdlNumberFormatNotValid: 'The EAN number format is incorrect',
      pvInstallNotFound: "PV installation not found",
      installationDetailsNotFound: "Installation details not found",
      installationNotUnlinked: 'Installation unlinking failed',
      installationNotAssignet: 'Installation assignment failed',
    }
  },
  InstallationEdit: {
    title: 'Edition of the installation',
    saveEditButton: 'Save',
    savedMessage: 'Installation saved successfully'
  },
  404: {
    notFoundCode: '404',
    notFound: 'We did not find the page',
    lostSentence:
      'It looks like an error has occurred, or you may have gotten lost.',
    backHome: 'What if you go back to reception?'
  },
  LoginAs: {
    loginAsLink: 'Login as'
  },
  ForgotPassword: {
    forgotPasswordLink: 'Forgot your password?',
    introductionMsg:
      'Please enter your email address and we will send you a message containing a link to renew your password.',
    emailSendMsg:
      'An email has just been sent to this Address. It contains a link that will allow you to regenerate your password. ',
    error: 'Unable to contact the server, please try again later.',
    resetButton: 'Change password'
  },
  ChangePassword: {
    changePasswordDescription: 'Please enter a new password in the form below',
    newPasswordLabel: 'New password',
    repeatedNewPasswordLabel: 'Repeated new password',
    saveButton: 'Save password',
    passwordsAreNotTheSame: 'Password must be the same in both fields (a new and repeated password).',
    passwordToShort: 'Password must be at least eight characters long.',
    passwordChangedSuccessfully: 'Your password has been successfully changed.',
    passwordChangedFailed: 'An error has occurred. Please try again later.',
    invalidRecoveryCodeError: "Your password reset link is invalid or expired.",
  },
  Tenant: {
    myhome: 'Mylight',
    myhomeFullName: 'mylight150',
    domuneo: 'Domuneo',
    domuneoFullName: 'Domuneo – MyLight Inside',
    planeteoui: 'Planète Oui',
    planeteouiFullName: 'Planète OUI – MyLight Inside',
    mypower: 'Engie My Power',
    mypowerFullName: 'Engie My Power – MyLight Inside'
  },
  DeviceType: {
    mst: 'Master - MST',
    mst_g3: 'Master - MG3',
    eth: 'Smartplug Ethernet - ETH',
    sw: 'Smartplug Switch - SW',
    sw_g3: 'Relay - MG3',
    gmd: 'Counter - GMD',
    gmd_g3: 'Counter - MG3',
    enp: 'Enphase - ENP',
    cmp: 'Counter - CMP',
    wsrhr: 'Wiser Relai',
    wsrts: 'Wiser Thermostat',
    wsrvl: 'Wiser Valve',
    tdac: 'Tado Air conditionner',
    tdht: 'Tado Thermostat',
    tph: 'Relay - TPH',
    tado_ac: 'Tado Air conditionner',
    tado_heater: 'Tado Thermostat',
    rs485: 'Counter RS485',
    bat: 'Battery',
    modbus_box: 'Pre-wired box (UPM)',
    modbus_part: 'Spare part'
  },
  CustomerEdit: {
    title: 'Customer modification',
    saveEditButton: 'Save',
    firstName: 'First name',
    lastName: 'Last Name',
    phoneNumber: 'Landline phone',
    mobileNumber: 'Mobile Phone',
    zipCode: 'Postal Code',
    city: 'City',
    editCustomerSuccess: 'Client successfully edited'
  },
  Messages: {
    deviceReadingsIncomplete:
      'We have noticed atypical behavior of the installation. The meter no longer communicates production / consumption data. \n\nThe values presented do not correspond to actual production / consumption. \n\nIf the problem persists, please contact our technical service.\n '
  },
  Dashboard: {
    numberOfInstallation: 'Installations',
    operational: 'Operational',
    pending: 'Pending activation',
    prosizerCallToAction: 'DISCOVER THE INNOVATIVE ENERGY SIMULATION SOFTWARE',
    prosizerLinkName: 'ACCESS THE PROSIZER'
  },
  Diagnostic: {
    startButton: 'Launch T48',
    incorrectPhase:
      'Impossible to launch the tests on this installation, PHASE not indicated',
    diagnosticStatusPending: 'In progress'
  },
  DiagnosticSummary: {
    summaryTitle: 'Tests',
    testNameLabel: 'Name',
    testSetStatus: 'State',
    pending: 'In progress',
    ok: 'Done',
    Failure: 'Error',
    create: 'Create',
    testErrorLabel: 'ERR',
    testOkLabel: 'OK',
    date: 'Date',
    emptyTestList: 'No test',
    details: 'DETAILS',
    menuTitle: 'Tests',
    emptyTestDetailsList: 'No result',
    Error: 'Error',
    Causes: 'PROBABLE CAUSE',
    Solutions: 'SOLUTION (S)'
  },
  MySmartBattery: {
    battery: 'My Smart Battery',
    addBattery: 'Add a My Smart Battery',
    addBatteryInfo: `
      <p class="m-0">You will need the following information:</p>
      <ul class="pl-4">
        <li>Serial number</li>
        <li>PDL number</li>
        <li>Energy Distributor</li>
      </ul>
    `,
    addBatteryELD: 'Then fill in the commissioning details',
    addBatteryELDInfo:
      'The information to be provided differs depending on the distributor concerned',
    addBox: 'Add',
    createBattery: 'Save',
    updateBattery: 'Save',
    deleteBattery: 'Remove',
    caeNumber: 'CAE number',
    pdlNumber: 'EAN number',
    enedisConnect: 'Enedis Connect',
    enedisConnectNumber: 'Enedis Connect',
    consuelNumber: 'Consuel number',
    consuelDeclaredPower: 'Declared installed power (Consuel)',
    consuelDeclaredPowerTooltip:
      'Maximum total power that the installation can produce.',
    connectionPower: 'Injection connection power',
    singlePhaseConnectionPowerTooltip:
      'Maximum power that the installation can inject into the network (limited to 6 kVA in single phase). NB: The connection power cannot exceed the installed power.',
    threePhaseConnectionPowerTooltip:
      'Maximum power that the installation can inject into the network (limited to 36 kVA in three phase). NB: The connection power cannot exceed the installed power.',
    strasbourgInjectionPdlNumber: 'Injection PDL',
    editConsuelNumberTitle: 'Consuel and acceptance',
    editConsuelNumberInfo: `The photovoltaic system is correctly installed and connected to the electrical panel
       The producer has the public authorization necessary for the installation of the photovoltaic system (town-planning permit, no opposition to the prior declaration ...) or certify that it does not require one
       The information collected in the Enedis-Connect affair are still in accordance with the request
       The electricity production unit concerned by this commissioning request is compliant with the requirements of Regulation CE2016 / 631, the decree concerning the connections to the electrical grid of June 9, 2020 and with Enedis rules
       If the commissioning of the photovoltaic system is carried out without the presence of an Enedis operator, the producer has to undertake the test of the decoupling protection (in the presence of the installer if necessary) : after coupling the installation, opening of Enedis circuit breaker and checking the decoupling (explicit message on the screen of the inverter or on the control system)`,
    editConsuelNumberCheckbox: 'I certify that my installation is compliant',
    energyProvider: `Energy provider`,
    mylightEnergyProvider: `mylight150`,
    domuneoEnergyProvider: `Domuneo`,
    planeteOuiEnergyProvider: `Planète Oui`,
    addSerialNumber: 'Add a My Smart Battery serial number',
    enterSerialNumber: 'Enter the serial number of the Smart Battery',
    snLimit: 'Maximum 5 Boxes are allowed',
    snExists: 'This serial number has already been added',
    invalidSN: `Unknown serial number. Please modify it`,
    scan: 'Scan number',
    serialNumber: 'Serial number',
    serialNumberBlank: '100 kWh:',
    serialNumbers_PLANETE_OUI: 'My Smart Battery Serial Numbers',
    serialNumbers_MYLIGHT: 'My Smart Battery Serial Number',
    volume: 'Battery volume',
    status: 'Status',
    pdl: 'Delivery point number',
    pdlError: 'Invalid Delivery Point Number',
    caeNumberError: 'Invalid CAE number',
    alreadyRegisteredSN: `The serial number XXXXXXXXXX is already associated.Please modify it.`,
    whereIsTheMylightSNQuestion: `Where can I find the MySmartBattery serial number?`,
    whereIsTheMylightSNAnswer: `This is the serial number on the back of the cellular modem or on the Smart Battery box`,
    whatIsEanFormat: `What is the format of the Delivery Point?`,
    EnedisFormat: `Format for Enedis: XX XX XX XXXXXX XX`,
    EsFormat: `Format for Strasbourg Electricity: XXXXX / E1 / XXXXXXX`,
    warning: `Warning !`,
    EanImportantStep: `Completing the PDL is a crucial step. If it contains errors, this could be blocking the further activation and therefore could cause a delay in the overall activation time.`,
    msb_SN_filled_true_PLANETE_OUI: 'Serial numbers have been entered',
    msb_SN_filled_false_PLANETE_OUI: 'Serial numbers have not yet been entered',
    msb_SN_filled_true_MYLIGHT: 'The serial number has been entered',
    msb_SN_filled_false_MYLIGHT: 'The serial number has not yet been entered',
    msb_SN_filled_details_PLANETE_OUI:
      'This status indicates whether the serial number (s) of the My Smart Battery has been entered.',
    msb_SN_filled_details_MYLIGHT:
      'This status indicates whether the serial number of the My Smart Battery has been entered.',
    msb_AC_filled_true_PLANETE_OUI: 'The activation codes have been entered',
    msb_AC_filled_false_PLANETE_OUI:
      'The activation codes have not yet been entered',
    msb_AC_filled_details_PLANETE_OUI:
      'This status indicates whether the customer has entered the activation code (s) for the My Smart Battery in their MyHome web interface.',
    msb_provider_registration_ok_true_PLANETE_OUI:
      'The electricity supply contract has been signed',
    msb_provider_registration_ok_false_PLANETE_OUI:
      'The electricity supply contract has not yet been signed',
    msb_provider_registration_ok_details_PLANETE_OUI:
      'This status indicates whether the energy supply contract has been signed by the customer. This information is automatically updated when the contract is signed. ',
    msb_enedis_ok_true_PLANETE_OUI: 'The connection request has been validated',
    msb_enedis_ok_false_PLANETE_OUI:
      'The connection request is awaiting validation',
    msb_enedis_ok_details_PLANETE_OUI:
      'This status indicates whether the customer’s delivery point has been linked to the energy supplier’s balance perimeter. This information is updated automatically. ',
    msb_ok_true_PLANETE_OUI: 'The Smart Battery is operational',
    msb_ok_false_PLANETE_OUI: 'The Smart Battery is not yet operational',
    msb_ok_true_MYLIGHT: 'The Smart Battery is operational',
    msb_ok_false_MYLIGHT: 'The Smart Battery is not yet operational',
    arpeTitle: 'ARPE',
    caeTitle: 'CAE',
    consuelTitle: 'Consuel',
    planeteOuiMsbDocumentsTitle: `Documents`,
    mylightMsbDocumentsTitle: `Injection Commissioning Request`,
    planeteOuiMsbDocumentsInfo: `In order to complete the file and activate the My Smart Battery, you must provide the signed ARPE and the CAE number to the energy supplier. You can download the ARPE model and upload the documents to this portal. You can enter the CAE number by clicking on the edit button of the My Smart Battery. Once the ARPE has been transmitted and the CAE number provided, these will be sent to the energy supplier.`,
    mylightMsbDocumentsInfo: `To proceed with the injection commissioning request with the GRD (ex : ENEDIS) and so activate the according MySmartBattery, you must fill in the MySmartBattery registration process with the serial number of the MySmartBattery, the number of the connection request with the GRD (ex : ENEDIS CONNECT), the number of the CONSUEL and upload the PDF of the CONSUEL.`,
    mylightMsbDocumentsAdditionalInfo: `If the GRD of the connection point does not allow us to proceed with the injection commissioning request, our support team will be in touch with you shortly.`,
    arpeInfo: `Download the document template in order to have it signed by the client.Once the document is signed, upload it through this portal.`,
    caeInfo: `Teletransmit the CAE to the energy supplier through this portal.`,
    consuelInfo: `Please upload here the PDF of the CONSUEL. This step is necessary to proceed with the injection commissioning request with the GRD.`,
    arpeDownload: `Download the ARPE Model`,
    arpeUpload: `Upload the signed ARPE`,
    caeUpload: `Upload CAE`,
    consuelUpload: `Upload CONSUEL`,
    choosePdf: 'Choose a document in PDF format',
    importFilePdfFormat: 'Import the file as PDF',
    importARPEPdfFile: 'Import the ARPE file as PDF',
    pdfUploadInfo_ARPE: '',
    pdfUploadInfo_CAE: '',
    pdfUploadInfo_CONSUEL: `Before sending your Consuel attestation, please review it and make sure it contains the good :
    AC Consuel number
    Delivery point number
    Contact information
    Then click on Save at the bottom of this window`,
    needPdf: 'Please choose a PDF file',
    pdfSendingError: `Error sending the file`,
    pdfSendingSuccess: 'The file has been sent successfully',
    sendDateCAE: 'Send CAE',
    sendDateARPE: 'Send ARPE',
    sendDateCONSUEL: 'Send CONSUEL',
    notSending: 'Not sent',
    date: 'date',
    contractNotSending: 'Contract not sent',
    contractNotSendingInformationMessage:
      "Le contrat sera automatiquement envoyé lorsque le client aura saisi le(s) code(s) d'activation(s) de la My Smart Battery lors de l'activation de son compte.",
    sendDateEnergyContract: `Date d'envoi du contrat de fourniture d'énergie`,
    volumeShort: 'Capacity',
    caeNumberShort: 'N°CAE',
    sendingARPEDate: 'ARPE',
    msbIcon: 'MSB',
    listFilter_msbCaeNumber_isNotNull: `Entered`,
    listFilter_msbCaeNumber_isNull: `Not entered`,
    listFilter_msbEnedisConnectNumber_isNotNull: `Entered`,
    listFilter_msbEnedisConnectNumber_isNull: `Not entered`,
    listFilter_msbConsuelNumber_isNotNull: `Entered`,
    listFilter_msbConsuelNumber_isNull: `Not entered`,
    listFilter_msbSendingARPEDate_isNotNull: `Sent`,
    listFilter_msbSendingARPEDate_isNull: `Not sent`,
    listFilter_msbSNFilled_isNotNull: `Entered`,
    listFilter_msbSNFilled_isNull: `Not entered`,
    listFilter_msbACFilled_isNotNull: `Entered`,
    listFilter_msbACFilled_isNull: `Not entered`,
    listFilter_msbEnedisOk_isNotNull: `Added to the balance perimeter`,
    listFilter_msbEnedisOk_isNull: `Not added to the balance perimeter`,
    listFilter_msbProviderRegistrationOk_isNotNull: `Energy supply contract signed`,
    listFilter_msbProviderRegistrationOk_isNull: `Energy supply contract unsigned`,
    listFilter_msbOk_isNotNull: `Operational Smart Battery`,
    listFilter_msbOk_isNull: `Not operation Smart Battery`,
    listFilter_msbType_isNull: `Planète Oui`,
    listFilter_msbType_isNotNull: `mylight150`,
    msbEmptyList: `No Smart Battery`,
    msbSNFilled_PLANETE_OUI: 'Enter serial numbers',
    msbSNFilled_MYLIGHT: 'Enter serial number',
    msbACFilled: 'Enter activation code',
    msbEnedisOk: 'Enedis electrical connection',
    msbProviderRegistrationOk: 'Power contract signing',
    msbOk: 'Activation',
    whereIsSN: 'Where is the MSB serial number located?',
    snLocation: `You can find the serial number on the back of the My Smart Battery box under the barcode`,
    serialNumbersEnteredMoreThan30DaysAgo_PLANETE_OUI: `Warning! This battery serial numbers were entered more than 30 days ago.`,
    serialNumbersEnteredMoreThan30DaysAgo_MYLIGHT: `Warning! This battery serial number was entered more than 30 days ago.`,
    msbStatus: {
      activated: 'activated at',
      created: 'saved at'
    },
    enedisGridInjection: {
      fill: 'to fill',
      inProgress: 'in progress',
      toValidate: 'to validate',
      requested: 'requested at',
      enabled: 'enabled at'
    },
    strasbourgElectricityAdministrativeProcedure: {
      fill: 'to fill',
      inProgress: 'in progress',
      toValidate: 'to validate',
      finished: 'finished at'
    },
    enteredOn: 'Entered on',
    enteredOnFormatDate: 'MM/DD/YYYY',
    onHold: 'On hold',
    energyDistributors: {
      ENEDIS: 'Enedis',
      ES: 'Électricité de Strasbourg'
    },
    energyDistributor: 'Energy Distributor',
    networkInjection: 'Network injection',
    errorLoadingMsbActivation:
      'An error occurred while loading data related to the MySmartBattery',
    uploadFile: 'Upload your file',
    validate: 'Validate',
    administrativeProcedure: 'Administrative procedure',
    forActivatedMySmartBattery:'To activate your customer\'s MySmartBattery we need to retrieve the perimeter agreement (ARPE) as well as the CAE number of the site for which you are going to establish the connection request.',
    downloadARPEModele:'Download the ARPE document template below before sending it to us once signed',
    deletionModalTitle: 'Delete saved MSB',
    deletionModalBodyTitle: 'Attention !',
    deletionModalBodyContent: 'You are about to delete the previously saved MSB. All information provided will be lost (serial number, PDL, etc.).',
    deletionButton: 'Delete MSB',
    deletionCancelButton: 'Cancel',
    deletionSuccess: 'MSB has been successfully deleted',
    enedisGridInjectionFormDescription: 'To finalize the commissioning, please complete all the fields below and send us the Consuel in PDF format.',
    enedisGridInjectionFormEnedisConnectFieldDescription: 'Enter the 13-character number in the following format: XXXXP4EXXXXXX',
    enedisGridInjectionFormConsuelNumberFieldDescription: 'Enter the 14-digit number',
    enedisGridInjectionFormDeclaredInstalledPowerFieldDescription: 'Enter the value entered in the Consuel',
    enedisGridInjectionFormConnectionPowerFieldDescription: 'Enter the value provided in the Enedis Connect request',
    strasbourgElectricityAdministrativeProcedureCaeNumberFieldDescription: 'Enter the 10-digit number',
    strasbourgElectricityAdministrativeProcedureInjectionPdlNumberFieldDescription: 
      'Enter the 16-character number : XXXXX/P1/XXXXXXX',
    errors: {
      serialNumberFormatNotValid: 'The serial number format is incorrect',
      serialNumberAlreadyUsed:
        'This serial number is already used in another installation',
      serialNumberDoesntExist: 'This serial number does not exist',
      updateSerialNumberFailed:
        'An error occurred while updating the MySmartBattery serial number',
      pdlNumberFormatNotValid: 'The EAN number format is incorrect',
      pdlNumberAlreadyUsed:
        'This EAN number is already used in another installation',
      updatePdlNumberFailed: 'An error occurred while updating the EAN number',
      createMsbFailed: 'an error occurred while adding the MySmartBattery',
      eldFormatInvalid: 'delivery point number format is invalid',
      msbApiUpdateStrasbourgInjectionPdlNotUnique: 
        'This injection PDL number is already used in another installation',
    }
  },
  BarcodeReader: {
    scan: 'Scan',
    rescan: 'Rescan',
    scanTitle: 'Barcode scan',
    scanInstruction: 'Please center the barcode in the middle of the image',
    wrongCode: 'Code not recognized',
    correctCode: 'The following code has been recognized:',
    UCGHelp: `Scan the serial number of the box.
     You will find the barcode of the serial number of the box:

     - On the label of the carton packaging of the box
     - On the inner door of the box`,
    MSTHelp: `Scan the activation code or the MAC address of your MST.
       You will find the barcode of the activation or the MAC address of the MST:

       - On the label of the carton packaging of the box (model V2)
       - On the back side of the MST (V2 model)
       - On the MST in the box`,
    MSBHelp: `Scan the serial number of the box.
     You will find the barcode of the serial number of the box:

       - On the label of the carton packaging of the box
     - On the inner door of the box\``
  },
  TestTitle: {
    'Test MST to the server connection':
      'Connection test from Master to server',
    'GMD communication test': 'GMD to Master communication test',
    'TPH communication test': 'Communication test from TPH to Master',
    'ETH communication test': 'Communication test from ETH to Master',
    'SW communication test': 'Communication test from SW to Master',
    'Test water heater consumption on TPH': 'TPH water heater control test',
    'Test Single Phase Consumption Measurement':
      'Consumption test of a single phase installation',
    'Single-phase Production measurement test':
      'Measurement test of the production of a single phase installation'
  },
  TestErrorCauses: {
    COM030: 'No internet connection or bad internet connection.',
    COM032: 'Bad CPL or GMD signal unstable',
    COM033: 'Bad CPL signal',
    COM034: 'Bad CPL signal',
    COM035: 'Bad CPL signal',
    RCE050:
      'The water heater does not seem connected or badly connected to the TPH device',
    TCM010_01: 'The measuring clamp is not connected or incorrectly positioned',
    TCM010_02: 'consumption is negative',
    TCM010_03: 'The measuring clamp is incorrectly positioned',
    TCM011_01: 'The measuring clamp is not connected or incorrectly positioned',
    TCM011_02: 'The measuring clamp is upside down',
    TCM011_03: 'The measuring clamp is incorrectly positioned'
  },
  TestErrorMessage: {
    COM030: 'No internet connection or bad internet connection.',
    COM032:
      'The GMD% MAC% device seems to communicate badly with the Master socket',
    COM033:
      'The TPH% MAC% device seems to communicate badly with the Master socket',
    COM034:
      'The ETH% MAC% device seems to communicate badly with the Master socket',
    COM035:
      'The SW% MAC% device seems to communicate badly with the Master socket',
    RCE050:
      'The water heater does not seem connected or badly connected to the TPH% MAC% device',
    TCM010_01:
      'No consumption measurement has been observed. The measuring clamp is probably not connected or incorrectly positioned',
    TCM010_02:
      'The consumption measured is negative. The measuring clamp is probably upside down or incorrectly positioned.',
    TCM010_03:
      'The production measurement indicates night production. The measuring clamp is probably incorrectly positioned. ',
    TCM011_01:
      'No production has been measured. The measuring clamp seems to be probably badly connected or badly positioned',
    TCM011_02:
      'The production measured is negative. The measuring clamp is probably upside down or incorrectly positioned.',
    TCM011_03:
      'The production measurement indicates night production. The measuring clamp is probably incorrectly positioned. '
  },
  TestErrorSolutions: {
    COM030:
      '• Check the internet connection \n • Please contact our support \n',
    COM032:
      '• Move the GMD power supply (take it out of the box) \n • Contact our support \n',
    COM033: '• Contact our support \n',
    COM034: '• Socket unplugged \n • Contact our support \n',
    COM035: '• Socket unplugged \n • Contact our support \n',
    RCE050: '• Contact our support \n',
    TCM010_01:
      '• Check the position of the measuring clamp \n • Contact our support \n',
    TCM010_02:
      '• Check the position of the measuring clamp \n • Contact our support \n',
    TCM010_03:
      '• Check the position of the measuring clamp \n • Contact our support \n',
    TCM011_01:
      '• Check the position of the measuring clamp \n • Contact our support \n',
    TCM011_03:
      '• Check the position of the measuring clamp \n • Contact our support \n',
    TCM011_02:
      '• Check the position of the measuring clamp \n • Contact our support \n'
  },
  UCG: {
    numberOfInstallation: 'Installations',
    operational: 'Operational',
    pending: 'Pending activation',
    doesNotHaveUCG: "I don't have a UCG",
    iHaveUCG: 'I have a UCG',
    noUCG: "I don't have UCG",
    ucg: 'UCG serial number',
    scan: 'Barcode reader',
    scanTitle: 'Scan the UCG barcode',
    ucgHelp: `Enter the serial number of the cabinet.
     You will find the serial number of the box:

    - On the lable of the carton packaging of the box
    - On the inner door of the box`,
    ucgHelpMobile: `Enter or scan the serial number of the cabinet.
    You will find the barcode of the serial number of the box:

    - On the lable of the carton packaging of the box
    - On the inner door of the box`,
    writeUCG: `Please enter the serial number of your mylight150 box.`,
    wrongUCG: `The cabinet number entered is not recognized. Please check its accuracy`,
    whereIsUCG: `Where is the UCG number located?`,
    positionUCG: `You can find the serial number inside the front of the box at the bottom left or on the box.`,
    ucgPreconfigurationWizardInfo: `In step 6 you can execute the automatic configuration of the devices in your mylight150 box by entering the serial number of your UCG (Central Management Unit). This wizard will automatically configure the devices in your mylight150 unit and their functions. Only valid for UCG.`,
    ucgPreconfigurationWizardAdditionalInfo: `The configuration wizard allows you to automatically configure the devices in your mylight150 box and their functions. If the box contains RS485 devices, these will be added automatically. Powerline devices must be paired before.`
  },
  UPM: {
    whereIsSerial: 'Where is the serial number located?',
    positionSerial:
      'You can find the serial number on the front of the box at the bottom left.'
  },
  WModbusPart: {
    whereIsSerial: 'Where is the serial number located?',
    positionSerial:
      'You can find the serial number on the label, on the back of the component.'
  },
  Master: {
    explainBarCodeReader:
      "Veuillez centrer le code barre au milieu de l'image. ",
    barCodeList: 'Code bar déchiffré :',
    master: "Code d'activation/Address MAC Ethernet de la Master",
    masterHelp: `Input the activation number or the MAC address of the MST.
    The barcode of the activation or the MAC address of the MST is visible :

    - On the label of the carton packaging of the box
    - On the back side of the MST
    - On the MST in the box`,
    masterHelpMobile: `Input or scan the activation number or the MAC address of the MST.
    The barcode of the activation or the MAC address of the MST is visible :

    - On the label of the carton packaging of the box
    - On the back side of the MST
    - On the MST in the box`,
    MAC: 'MAC Address',
    MSTCODE: 'Activation code',
    sMAC: 'You have input the MAC address of the Master',
    sMSTCODE: 'You have input the code of activation of the Master',
    masterError: 'Invalide code',
    scanTitle: 'Scan du code barre de la Master',
    scan: 'Lecteur de code barre',
    invalid: '(invalide)',
    timeout:
      'The application cannot read the barcode. Please enter it manually',
    eraseMessage:
      'Attention: changing Master will erase the configuration of every device. Are you sure to continue?',
    updateMST: 'Change Master',
    connectedMST: 'Master connected',
    disconnectedMST: 'Master disconnected',
    recontactMaster: 'Contact Master again',
    selectTypeConnection: `Please specify how it is connected to internet`,
    selectMasterDisconnect: `The master you have selected is non connected, please verify your connexion`,
    receptionSignalNotOpti: `The signal reception is not optimal we advise you to try to improve it by:`,
    extendingAntenna: `Extending the antenna to a window via its 3m cable`,
    extendingModem: `Deporting the modem and the antenna to a window via a mylight150 COM-1 PLC socket`,
    reorientAntenna: `Reorienting the antenna`,
    contactSupport: `Contacting support`,
    warningIntermittentConnexion: `If you do not modify anything, you may notice intermittent connections while using the product, but no modification is required.`,
    information: 'Information:',
    informationRebootMasterWhenChangeTypeConnection: `When you change the connection type of the Master to the internet, please restart the Master by pressing the circuit breaker so that it is taken into account`,
    warningMessagelowerSignalForAdapterCpl: `The CPL throughput between the Master and the COM-1 socket seems limited, you can improve it by:`,
    verifCom1ConnectToMultipleSocket: `- Checking that the COM-1 socket is not connected to a multiple socket`,
    verifCom1DistanceOtherCpl: `- Checking that the COM-1 socket is more than one meter from other PLC equipment (see manual or contact technical support if unable to remove this other equipment)`,
    verifCom1WifiRepeater: `- Using a Wifi repeater`,
    verifCom1CellularModem: `- Using a cellular modem`,
    verifCom1QualityElectricityNetwork: `- Checking the quality of the electrical network (section of cables, filters, fittings ...)`,
    adapterCplNotDetected: `You said that the Master should be connected to the Internet via a mylight150 PLC socket (COM-1), but this does not seem to be the case, no mylight150 device fulfilling this role has been detected. Please check .`,
    bestPracticeGoodRateFlow: `Best practices to guarantee a good flow between Master and COM-1:`,
    com1ConnectToMultipleSocket: `- The COM-1 socket must not be connected to a multiple socket`,
    com1DistanceOtherCpl: `- The COM-1 socket must be more than one meter from other PLC equipment`,
    warningGsmMaybeDisconnected: `You said that the Master should be connected to the Internet via a cellular modem (GSM router), but this does not seem to be the case, no device fulfilling this role has been detected. Please check.`
  },

  RegistrationMenu: {
    pv_installation: 'Installation',
    partnership: 'Third party accounts',
    battery: 'My Smart Battery',
    ucg: 'UCG',
    master: 'Master',
    devices: 'Devices',
    functions: 'Functions',
    wiring: 'Connections',
    stepperTitle: 'Completion of registration',
    mainTitle: 'Completion of registration',
    finish: 'Complete the installation',
    summary: 'Summary',
    devicesSummary: 'Devices and functions'
  },
  DevicesInscription: {
    pleaseFinishInscription: `The registration of your devices comes to an end. Please make sure everything looks ok before validating your registration. To do so click on complete devices registration at the bottom of this screen.`,
    skipRegistrationStep: 'Skip this step',
    finalizeInstallation: 'Complete device registration',
    deviceNotAssigned: 'Unassigned devices',
    warningYouDontHaveAssignedSomeDevice:
      'Please note, you have not assigned a function to all devices, their functions will be automatically set to "Other"',
    inscriptionInProgress: 'Registration being finalized',
    notPossibleToDefineTwoGlobalConsumptionCounter:
      'It is not possible to define 2 total consumption meters.',
    functionAlreadyAffectedAtAnOtherDevice:
      'The function has already been assigned to another device:',
    continueInscription: `Continue registration`,
    waitingFinishInscription: `Registration in progress. It can take few minutes. Please wait.`,
    sendInscriptionSummary: `Share registration state by Email`,
    sendInscriptionSummaryEmailInfo: `You can enter multiple email addresses separated by a ";"`,
    sendInscriptionSummaryEmailExample: `Example: "first@domain.com;second@domain.com"`,
    sendInscriptionSummaryEmailFeatureInfo: `Share the registration status with your partners.\nBy entering your partners' email, a summary report of the registration and its status will be sent.`
  },
  Devices: {
    counter: 'Counter',
    activationCode: 'Activation code',
    roomToDefine: 'Room to be defined',
    type: 'Type',
    name: 'Name',
    mac: 'Mac',
    room: 'Room',
    saved: 'saved',
    unsaved: 'not registered',
    on: 'CONNECTED',
    offline: 'DISCONNECTED',
    deviceState: 'State',
    stateOn: 'On',
    stateOff: 'Off',
    alaska_home: 'Housing',
    doneListTitle: 'Functions',
    todoListTitle: 'Unused devices',
    unusedFunctions: 'Devices with undefined functions',
    emptyFunctionsList: 'No device without function',
    emptyDoneList: 'No device',
    roomType: 'Room type',
    roomName: 'Room name',
    deviceList: 'Devices connected to the Master',
    firmware: 'Firmware version',
    function: 'Device function',
    swG3Function: `Type of device controlled`,
    defineFunction: 'Function to be defined',
    listTitle: 'Complete System',
    modbusDevicesTitle: 'Devices connected by wire (Modbus)',
    wmodbusDevicesTitle: 'Devices connected wirelessly (W-Modbus)',
    modbusAddress: `Modbus Address`,
    modbusReference: `Reference`,
    refreshList: 'Refresh the list',
    split: '3 measures',
    merge: '1 measure',
    functionName: 'Function name',
    swG3FunctionName: `Name of the device controlled`,
    functionType: 'Type of device measured',
    functionEditionTitle: 'Functions (s)',
    affectFunction: 'Assign a function',
    greenplay: 'Activate Green Play',
    deviceEditing: `Device configuration`,
    negativePower: `
      The measurement returned by the device is negative.
      Under normal conditions, the function assigned to this device must not be negative.
      Please refresh the list and if the problem persists check the correct installation,
      `,
    noFunctionLabel: 'No function assigned',
    greenPlayLabel: 'Green Play enabled',
    plcType: 'CPL',
    rs485Type: 'RS485 wired',
    addDeviceTitle: 'Add a new device',
    addDeviceType: `What type of device?`,
    addDeviceTitlePLC: 'Add a CPL device',
    addDeviceTitleRS485: 'Add a wired RS485 counter',
    addDevicePLC: 'Device connected in PLC',
    addDeviceRS485: 'Device connected in wired RS485',
    addDeviceWMODBUS: 'Wireless device (W-Modbus)',
    selectWModbusDeviceType: 'Model selection',
    addDeviceTitleWModbusBox: 'Add a pre-wired box (UPM)',
    addDeviceWModbusBoxSerial: 'Pre-wired box serial number',
    addDeviceTitleWModbusPart: 'Add a spare part',
    addDeviceWModbusPartSerial: 'W-Modbus component serial number',
    connectedRS485Devices: 'Connected RS485 devices',
    chooseReference: 'Choose the reference',
    chooseAddress: 'Choose the Address',
    macOrAC: `Activation code or Address MAC CPL of the device`,
    invalidMacOrAC: 'Invalid code',
    plcConnectingInProgress: `The Device being connected`,
    plcConnectingInProgressExtended: `The connection can take from 1 to 5 minutes.
        Please wait,
    reference: 'Référence'`,
    deviceDisconnectedError: `A %deviceName% appears disconnected. Please check the connection or remove it from the registration.`,
    disconnectedModbusDevice: `Some devices connected in Modbus are disconnected, please refresh the list of devices or check the connection.`,
    forbiddenDeviceForGridType: `The installation is declared in Single phase, while this device is configured for the three phase`,
    deletingModbusDevice: `The device connected via Modbus has been successfully deleted`,
    setGridType: `You must enter the type of installation (single - phase or three - phase) to be able to change the function of a device.You can change this information in step 1 Installation`,
    measureOfPrefix: `Measure of`,
    measureAndControlOfPrefix: `Measure and control of`,
    controlOfPrefix: `Control of`,
    swG3WaterHeaterFunctionSelected: `The configuration of the Relay G3 when controlling the water heater does not allow the consumption of this same water heater to be measured.Please ensure that a metering element is configured to measure the water heater(GMD G3 or RS485)`,
    rs485WaterHeaterFunctionSelected: `The configuration of the RS485 meter for water heater measurement does not allow the water heater to be controlled. Please ensure that a control element is configured to control the water heater (Relay G3 or TPH)`,
    gmdG3CTWaterHeaterFunctionSelected: `The configuration of the GMD G3 in measurement of water heater does not allow to control the water heater.Please make sure that a measuring element is configured to measure the water heater(Relay G3 or TPH) `,
    signalReceptionGSM: 'Reception of GSM signal',
    rssi: 'rssi',
    rscp: 'rscp',
    pclRateBetweenMasterAndComOne:
      'CPL speed between the Master and the COM-1 plug:',
    tx: `Tx`,
    rx: `Rx`,
    devicesConfigurationModeTitle: 'Device configuration',
    devicesConfigurationModeAuto: 'Start the configuration wizard',
    devicesConfigurationModeManual: 'Configure my devices manually',
    startConfigurationAssistance: `Start the configuration wizard`,
    configurationAssistance: `Configuration wizard`,
    configurationAssistanceCantFinish: `We detected that some CPL devices (% devicesName%) had not yet been paired with the Master. In order for the wizard to configure the installation, please pair the missing devices: No function has been assigned yet`,
    confirmAffectDevicesFunction: `The following functions will be assigned to the above devices, would you like to apply them?`,
    preconfigurationAppliedWithSuccess: `Preconfiguration successfully applied.`,
    successAffectDevicesFunction: `All pre-configurable functions have been assigned. If additional devices in the box have been added, these are not pre-configured. Please confirm and complete the assignment of functions in the next step.`,
    devicesRS485Added: `The following RS485 devices have been added:`,
    address: `Address`,
    UCG_CONTAINS_TOO_MANY_GMD: `The UCG model installed has several GMDs. Therefore it is not possible to preconfigure the GMD functions`,
    UCG_CONTAINS_TOO_MANY_TPH: `The UCG model installed has several TPHs. Therefore it is not possible to preconfigure the functions of TPH`,
    MST_CONFIG_FILE_CONTAINS_MORE_GMD_THAN_UCG: `Additional GMDs have been paired to the Master. Therefore it is not possible to preconfigure the GMD functions`,
    MST_CONFIG_FILE_CONTAINS_MORE_TPH_THAN_UCG: `Additional TPHs have been paired with the Master. Therefore it is not possible to preconfigure the functions of TPH`,
    MST_CONFIG_FILE_CONTAINS_LESS_GMD_THAN_UCG: `One or more GMDs paired with the Master are missing. Therefore it is not possible to preconfigure the functions of these devices`,
    MST_CONFIG_FILE_CONTAINS_LESS_TPH_THAN_UCG: `One or more TPH paired with the Master is missing. Therefore it is not possible to preconfigure the functions of these devices`,
    UCG_MODEL_CONTAINS_MANY_MODBUS_DEVICE_WITH_SAME_REFERENCE: `The UCG model installed includes several RS485 devices with the same reference. Therefore it is not possible to preconfigure the functions of these devices`
  },
  RoomName: {
    workshop: 'Atelier',
    other_area_type: 'Other',
    library: 'Library',
    washroom: 'Laundry room',
    office: 'Office',
    cellar: 'Cave',
    storeroom: 'Cellar',
    bedroom: 'Bedroom',
    boiler_room: 'Boiler room',
    corridor: 'Corridor',
    kitchen: 'Cuisine',
    outbuilding: 'Dependency',
    dressing_room: 'Dressing',
    entry: 'Entry',
    garage: 'Garage',
    alaska_home: 'Housing',
    garden: 'Jardin',
    swimming_pool: 'Swimming pool',
    dining_room: 'Dining room',
    bathroom: 'Bathroom',
    shower_room: 'Shower room',
    playroom: 'Games room',
    sports_room: 'Gym',
    living_room: 'Living room',
    porch: 'Veranda',
    water_closet: 'WC'
  },
  DevicesFunctions: {
    asoka_red_plug: 'Red device',
    aspirator: 'Aspirateur',
    other_device_type: 'Other',
    bbq_hot_stone: 'BBQ Plancha',
    battery: 'Battery',
    kettle: 'Kettle',
    wine_cellar: 'Wine cellar',
    water_heater: 'Water heater',
    water_heater_switch: 'Water heater switch',
    composite_device: 'Composite device',
    asoka_electric_counter: 'Consumption meter',
    freezer: 'Freezer',
    game_console: 'Game console',
    electric_car: 'Electric car',
    speaker: 'Pregnant',
    iron: 'Iron',
    oven: 'Four',
    refrigerator: 'Frigidaire',
    refrigerator_freezer: 'Frigidaire congélateur',
    deep_fryer: 'Fryer',
    toaster: 'Toaster',
    stereo_system: 'Hifi',
    home_cinema_system: 'Home cinema',
    production_counter_ignored: 'Counter ignored',
    printer: 'Printer',
    lamp: 'Lampe',
    dishwasher: 'Dishwashers',
    washing_machine: 'Washing machine',
    washing_drying_machine: 'Washing machine and dryer',
    dvd_player: 'DVD player',
    coffee_machine: 'Coffee machine',
    microwave_oven: 'Microwave',
    computer: 'Computer',
    production_counter: 'Production counter',
    pump: 'Pump',
    heater: 'Radiator',
    radio: 'Radio',
    razor: 'Razor',
    alarm_clock: 'Alarm clock',
    food_processor: 'Food processor',
    hair_dryer: 'Hair dryer',
    dryer: 'Tumble dryer',
    phone: 'Phone',
    tv: 'Television',
    ventilator: 'Fan',
    video_projector: 'Video projector',
    virtual: 'Virtual device',
    tado_ac: 'Tado Air conditionner',
    tado_heater: 'Tado Thermostat',
    wiser_hrelay: 'Wiser Relai',
    wiser_thermostat: 'Wiser Thermostat',
    wiser_valve: 'Wiser Valve',
    my_smart_battery: 'My Smart Battery'
  },
  DeviceDetail: {
    mst1: ` • Connect directly to the electrical wall outlet near your Internet box.
            • With the supplied Ethernet cable, connect the SmartPlug to your Internet box.
            • Connect the plug of your Internet box to the SmartPlug.
`,
    mst2: `Attention! Any SmartPlug plugs directly into a wall outlet to limit interference. If you must use a power strip, it plugs into the SmartPlug and not into the wall socket. `,

    mst_g3mono1: `The Smart Master G3 is a 3-in -1 meter which measures photovoltaic production, the overall consumption of the building and which manages large electrical appliances(including water heaters).

      It therefore includes 3 metering inputs(consumption, production, equipment).This meter has several internet connectivity options: by RJ45 Ethernet cable, by PLC socket or by cellular modem(optional).Finally it has external connectivity such as an RS485 gateway and 2 Ethernet ports.
      `,
    mst_g3mono2: `
    Connection:

    • Choose and connect a P + N 2A curve C circuit breaker to the house installation
    • Supply the Neutral(N) from the neutral of the circuit breaker
    • Supply phase(L1) from the single phase of the circuit breaker
      `,
    mst_g3mono3: `
    Branchement transformateur de courant :
    `,
    mst_g3mono4: `
    Current transformers are placed around the house phase cables, as shown in the diagram above.

    In the case of a three-phase installation, two other current transformers must be placed around the cables L2 and L3, and connected respectively to the inputs CT2 and CT3 of the equipment.

    The connection of the current transformers must be complete and the connector screwed.`,
    mst_g3mono5: `Attention! The connector is polarized, never force to insert it.`,

    mst_g3tri1: `The Smart Master G3 is a 3-in -1 meter which measures photovoltaic production, the overall consumption of the building and which manages large electrical appliances(including water heaters).

      It therefore includes 3 metering inputs(consumption, production, equipment).This meter has several internet connectivity options: by RJ45 Ethernet cable, by PLC socket or by cellular modem(optional).Finally it has external connectivity such as an RS485 gateway and 2 Ethernet ports.
`,
    mst_g3tri2: `
     Connection:

    • Choose and connect a 4P 2A curve C circuit breaker to the house installation
    • Supply the Neutral(N) from the neutral of the circuit breaker
    • Supply the three phases(L1, L2, L3) from each phase of the installation
       `,
    mst_g3tri3: `
     Current transformer connection: `,
    mst_g3tri4: `
     Current transformers are placed around the house phase cables, as shown in the diagram above.

     In the case of a three-phase installation, two other current transformers must be placed around the cables L2 and L3, and connected respectively to the inputs CT2 and CT3 of the equipment.

     The connection of the current transformers must be complete and the connector screwed.
     `,
    mst_g3tri5: `Attention! The connector is polarized, never force to insert it.`,

    eth1: `• Connect directly to the electrical wall outlet.
• To connect a device to the Internet, connect it to the SmartPlug using the supplied Ethernet cable.
• Connect the power supply of this device to the SmartPlug.
`,
    eth2: `Attention! Any SmartPlug plugs directly into a wall outlet to limit interference. If you must use a power strip, it plugs into the SmartPlug and not into the wall outlet.`,
    sw1: `• Connect directly to the electrical wall outlet.
    • Connect the power supply of the device of your choice to the SmartPlug.
`,
    sw2: `Attention! Any SmartPlug plugs directly into a wall outlet to limit interference. If you must use a power strip, it plugs into the SmartPlug and not into the wall outlet.`,
    sw_g31: `• Connect directly to the electrical outlet.
    • Connect the power supply of the device of your choice to the SmartPlug.`,
    sw_g32: `Attention! Any SmartPlug plugs directly into a wall outlet to limit interference. If you must use a power strip, it plugs into the SmartPlug and not into the wall socket.`,
    gmdmono1: `Current transformers are placed around the house phase cables, as shown in the diagram below.The jack at the other end must be plugged into the CT1 input of the mylight150 meter.`,
    gmdmono2: `Attention! Respect the direction of the current indicated on the current transformer by two arrows on its top, in accordance with the diagram below`,
    gmdtri1: `The current transformers are placed around the house phase cables L1, L2, L3, as shown in the diagram below.The jack sockets at the other end must be connected to the CT1, CT2 and CT3 inputs of the mylight150 counter respectively.`,
    gmdtri2: `Attention! Respect the direction of the current indicated on the current transformer by two arrows on its top, in accordance with the diagram below`,
    gmd_g3mono1: `The current transformers are placed around the house phase cables, as shown in the diagram below. The jack at the other end must be plugged into the CT1 input of the mylight150 meter.`,
    gmd_g3mono2: `Attention! Respect the direction of the current indicated on the current transformer by two arrows on its top, in accordance with the diagram below`,
    gmd_g3tri1: `The current transformers are placed around the cables L1, L2, L3 of the house phase, as shown in the diagram below.The jack sockets at the other end must be connected to the CT1, CT2 and CT3 inputs of the mylight150 counter respectively.`,
    gmd_g3tri2: `Attention! Respect the direction of the current indicated on the current transformer by two arrows on its top, in accordance with the diagram below`,
    rs485_mc11: `It measures the consumption of an electrical appliance in a single-phase or three-phase installation. It communicates in RS485 with the Smart Master G3.`,
    rs485_mc12: `
    Connection:
      • Choose and connect a circuit breaker suitable for the load
      • Supply the Neutral (N) from the neutral of the circuit breaker
      • Supply phase (L1) from the single phase of the circuit breaker
      • Connect the load to the meter`,
    rs485_mc31: `It measures the consumption of an electrical appliance in a single-phase or three-phase installation. It communicates in RS485 with the Smart Master G3.`,
    rs485_mc32: `
   Connection:
      • Choose and connect a circuit breaker suitable for the load
      • Supply the Neutral (N) from the neutral of the circuit breaker
      • Supply the three phases (L1, L2, L3) from the phases of the circuit breaker
      • Connect the load to the meter`
  },
  Partnerships: {
    partners: 'Third Party Accounts',
    enphaseSystemName: 'Enphase',
    tadoSystemName: 'Tado',
    wiserSystemName: 'Wiser',
    validate: 'Save',
    connect: 'Connect',
    cancel: 'Cancel',
    connectedStatus: 'connected',
    inProgressStatus: 'In progress',
    disconnectedStatus: 'not connected',
    connectingInProgress: `Registration of third-party accounts is not yet completed`
  },
  EnphasePartnership: {
    emailExists: `Do you want to use the same Email Address for registration on Enlighten?`,
    email: `Customer email`,
    emailConfirmation: `Confirm email`,
    emailWarning: `The e-mail will also be updated in the mylight150 customer file`,
    enphaseSystemId: `Enphase System ID`,
    enphaseInstallerId: 'Enphase Installer ID',
    whereIsInstallerID: {
      title: `Where to find the Enphase Installer ID?`,
      step1: `Access to your <a class='text-primary' target='_blank' href='https://enlighten.enphaseenergy.com/'>Enlighten</a> account.<br/>
        Once logged in, go to the <b>menu > Account > Company profile</b>.`,
      step2: `Scroll <b>down the page</b> Company profile.<br/>
        You will find the Installer ID (<b>XXXXXX</b>) in the <b>List of Reference Systems</b> section.`
    },
    enphaseInstallerIdDescription:
      'Immediately assign you ENVOY access rights, to do this register the Enphase Installer ID',
    enphaseDetails: 'Details of the Enphase connection',
    envoySerialNumber: `Serial number of the Envoy`,
    envoyRegistered: `Is the Enphase system already registered?`,
    wrongEnvoyFormat: `The Envoy number must contain 12 characters.`,
    updateEmail: `Edit email`,
    wrongSystemIdFormat: `System Id must contain between 5 and 7 characters.`,
    wrongEmail: `Please check the email`,
    wrongInstallerIdFormat:
      'The Installer ID must contain at least 2 numeric characters.'
  },
  EnphaseErrors: {
    email: 'This email is already used on Enphase',
    emailExists: `The user with this email already exists!`,
    registration: `Enphase registration error, please try again`,
    serialNumber: `The serial number is not valid!`,
    activationCode: `The activation code is not valid!`,
    owner: `This Envoy serial number is already registered, please contact Enphase customer service.`,
    systemId: `The system ID of the device is not valid`,
    emailUpdate: `Error updating an email, please try again.`,
    unknown: `An unknown error occurred while saving Envoy, please try again.`
  },
  TadoPartnership: {
    login: 'You must log into the Tado window.',
    connectingTado: 'Tado Connection',
    selectTadoHome: 'Select the Tado Home you wish to connect',
    tadoHome: 'Tado Home',
    connectTadoHome: 'Tado Home Connection',
    resumeTadoConnecting: 'Resume Tado connection',
    disconnectTado: 'Disconnect',
    detectedDevices: 'The following devices have been detected:',
    connectedDevices: 'The following devices have been connected:',
    reopenTadoLoginWindow: 'Reopen the Tado login window',
    validate: 'Validate',
    devicesAddingError: `An unknown error occurred while registering Tado, please try again.`,
    devicesAddingSuccess: `Tado devices have been successfully registered`,
    deviceRemovingError: `An unknown error occurred while deleting the Tado device`,
    deviceRemovingSuccess: `Tado devices have been successfully removed`,
    tadoDisconnectingError: `An unknown error while disconnecting Tado`,
    tadoDisconnectingSuccess: `Tado was successfully disconnected`,
    noTadoDevices: `Selected Tado Home has no device`,
    noDetectedDevices: 'No device was detected'
  },
  TadoErrors: {
    getDevices: 'Error reading Tado Home devices, please try again.',
    homeNotFound: 'Tado Home cannot be found'
  },
  Success: {
    updateCustomer: `The client has been updated successfully`,
    updateInstallation: `The installation was successfully updated`,
    updateUCG: `The UCG was successfully updated`,
    updateMaster: `The Master has been successfully updated`,
    updateDevice: `The device has been updated successfully`,
    updateDevices: `The devices have been successfully updated`,
    updateFunctions: `The device functions have been updated successfully`,
    updateMSB: `My Smart Battery was successfully updated`,
    finishInscription: `Device registration succeeds`,
    sendInscriptionSummary: `The email to share the registration status has been sent`
  },
  SummaryWarnings: {
    missingPLCHub: `You have declared that the Master should be connected to the Internet via another mylight150 PLC socket, but this does not seem to be the case, no device fulfilling this role has been detected`,
    missingGSM: `You said that the Master should be connected to the Internet via a cellular modem (GSM router), but this does not seem to be the case, no device fulfilling this role has been detected.`
  },
  Errors: {
    saveCustomer: 'Error creating customer',
    saveCustomerSrs:
      'An error occurred while customer creation. Try again later. If the problem persists, please contact our technical service',
    assignError: `The user was successfully created, but an error occurred while associating it with an Installer`,
    unknown: `Unknown error`,
    serverError: 'An error has occurred.',
    dataAccessBlocked: `The customer does not authorize access to his installation data and his consumption / production data.`,
    masterAlreadyUsed: `This device is already associated with a user`,
    mstNotConnected: `Unable to join the Master`,
    mstNotConnectedExtended:
      'An error has occurred. Unable to contact the Master. Try Again.' +
      'If the problem persists check that the Master is properly connected to the internet.',
    mstInvalidAC: 'Invalid activation code',
    mstInvalidMAC: 'Invalid MAC address',
    mstConnectedWithGSM:
      'The selected connection mode seems incorrect (a modem is detected)',
    mstConnectedWithPLC:
      'The selected connection mode seems incorrect (a PLC mylight150 socket is detected)',
    noSignalDetected: 'No signal detected',
    unknownError: 'Master unknown error',
    plcError: `An error has occurred, check the activation code or the MAC address of the device you are trying to connect and check if your Master device is correctly connected and supplied with power .`,
    finishInscription: `An error occurred while registering the devices`,
    tooLongStringError: `The entry is too long, maximum 50 characters are allowed`,
    numberFormatError: `Invalid number format`,
    countryIsNotSupported: `This Country is not supported`,
    geocoderError: `An error occurred while searching for the Address`,
    rackingPowerError: `Invalid value racking power`,
    sendInscriptionSummary: `The email address contains invalid characters`,
    customerNotFound: 'User not found. Please contact our technical service',
    installationUserNotFound: `Cannot retrieve installation data, user not found. Please contact our technical service`,
    wModbusBoxSerialError: 'The serial number of the box is invalid',
    wModbusBoxDeviceNotFound: 'No device found linked to this serial number',
    wModbusBoxDeviceUnavailable:
      'This device is already registered. Please verify the serial number or contact our technical service',
    wModbusPartDeviceUnavailable:
      'This device is already registered. Please verify the serial number or contact our technical service',
    wModbusPartSerialError: 'The serial number of the spare part is invalid',
    wModbusPartDeviceMissingDevices:
      'At least one RS485 device must be associated',
    deletionMyLightMsb: 'This MSB cannot be deleted',
  },
  ErrorsBack: {
    customerMailAlreadyExist: 'This email is already in use'
  },
  PowerContractType: {
    flat: 'Base',
    custom: 'Off-peak hours / Full hours',
    predefined: 'Autre'
  },
  PowerContractOption: {
    ejp: 'EJP',
    tempo: 'Tempo',
    other: 'Other'
  },
  StepError: {
    room: 'An error occurred during the step: "Creation of parts"',
    device: 'An error occurred during the step: "Creation of devices"',
    deviceVrt:
      'An error occurred during the step: "Creation of the virtual device \'" ',
    greenPlay:
      'An error occurred during the step: "Activation of the greenplay"',
    unknown: 'An unknown error occurred during registration'
  },
  StepTodo: {
    summary: `In order to finalize the registration all the steps must be validated`,
    installation: `Fields followed by an asterisk are compulsory`,
    functionsNoCounter: `At least one production meter or one global consumption meter must be defined`,
    functionsForbidden: `One or more devices are configured for a three- phase installation, while the current installation is declared as Single phase .`,
    modbusProductionCounter:
      'The physical counter can display a negative value, it will not impact the installation'
  },
  ToolsPairing: {
    verifPairing: `Pairing check`,
    testPairing: `Pairing test`,
    completeSystem: 'Complete system',
    notPermissionToAccessPairingToolkit: `You do not have permission to access the device test tool`,
    refreshPower: `Refresh the powers`
  },
  SidebarGuide: {
    gettingStartedGuideLabel: 'Getting started guide',
    hardwareRegistrationTutorialLabel: 'Hardware registration tutorial',
    mistakesNotToMakeLabel: 'Mistakes not to make',
    guideToProceduresLabel: 'Guide to procedures',
  }
};
