import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, MutationResult, Query, QueryResult } from 'react-apollo';
import { translate, TranslationFunction } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import ChangePasswordForm from './ChangePasswordForm';
import { Background } from './../../ui/Background/Background';
import { LoginBox } from './../../ui/LoginBox/LoginBox';
import { ISnackBarParams } from './../../stores/userFeedback/snackbarStore';

const CHANGE_PASSWORD = gql`
  mutation changePassword($recoveryCode: String, $password: String) {
    changePassword(recoveryCode: $recoveryCode, password: $password) {
      success
    }
  }
`;

const VALIDARE_RECOVERY_CODE = gql`
  query validateRecoveryCode($recoveryCode: String) {
    validateRecoveryCode(recoveryCode: $recoveryCode) {
      success
      valid
    }
  }
`;

interface IPasswordChange {
  success: boolean;
  valid?: boolean;
}

interface IQueryResponse {
  validateRecoveryCode: IPasswordChange;
}

interface IMutationResponse {
  changePassword: IPasswordChange;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  t: TranslationFunction;
  showSnackBar?: (params: ISnackBarParams) => void;
}

interface IMatchParams {
  recoveryCode?: string;
}

const ChangePasswordView = ({
  t,
  showSnackBar,
  match: {
    params: { recoveryCode }
  }
}: IProps) => {
  const [isRecoveryCodeValid, setIsRecoveryCodeValid] = useState<boolean>(false);

  return (
    <Query
      query={VALIDARE_RECOVERY_CODE}
      variables={{ recoveryCode }}
      fetchPolicy={'network-only'}
      errorPolicy={'all'}
      onCompleted={(response: IQueryResponse) => {
        if (response) {
          setIsRecoveryCodeValid(!!response.validateRecoveryCode.valid);
        }
      }}
      onError={() => {
        showSnackBar!({
          type: 'error',
          text: t('passwordChangedFailed')
        });
      }}
    >
      {({
        loading: queryLoading,
      }: QueryResult<IQueryResponse>) => {
        return (
          <Mutation mutation={ CHANGE_PASSWORD }>
            {(
              changePassword,
              { loading, data, called }: MutationResult<IMutationResponse>
            ) => {
              return (
                <Background>
                  <LoginBox>
                    <ChangePasswordForm 
                      onSubmit={ (password: string ) =>
                        changePassword({
                          variables: {
                            recoveryCode,
                            password
                          }
                        })
                      }
                      valid={ isRecoveryCodeValid }
                      loading={ loading || queryLoading }
                      called={ called }
                      success={ data && !!(data.changePassword.success) }
                    />
                  </LoginBox>
                </Background>
                );
              }}
            </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(translate(['ChangePassword'])(ChangePasswordView)); ;
