import React from 'react';
import MediaQuery from 'react-responsive';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { get } from 'lodash';

import './customerHeader.scss';
import RegistrationStatus from '../../constants/REGISTRATION_STATUS';
import { ICustomerExtended } from '@mylight/data-model';
import { HelpButton } from '../HelpButton/HelpButton';
import Spinner from '../Spinner/Spinner';
import LoginAsLink from '../../components/login_as/LoginAsLink';
import {
  getTenantLogo,
  shouldDisplayContinueInstallation
} from '../../utils/tools';
import { BREAK_POINT_SAFE_MIN_WIDTH } from '../../constants/BREAK_POINT_WIDTH';
import BadgeStatus from '../BadgeStatus/BadgeStatus';
import ContinueSubscriptionButton from '../../components/redirect/ContinueSubscriptionButton';
import IsOnlineIndicator from '../../components/systemStatus/IsOfflineIndicatorQuery';
import InstallationSettings from './../../components/customers/installation/settings/InstallationSettings';

interface IProps {
  t: TranslationFunction;
  customer?: ICustomerExtended;
  loading: boolean;
  error?: string;
}

const RedirectButton = ({ customer }: { customer: any }) =>
  shouldDisplayContinueInstallation(customer.status) ? (
    <ContinueSubscriptionButton
      customerId={customer.id}
      color="primary"
      textKey="inscriptionFull"
      pvInstallationId={customer.pvInstallationId}
      srsRegistrationStatus={customer.status}
    />
  ) : (
    <LoginAsLink email={customer.email} disabled={!customer.loginAsAllowed} />
  );

const CustomerHeader = ({ t, customer, loading, error }: IProps) => {
  if (customer) {
    const { status } = customer;

    return (
      <MediaQuery query={`(min-width: ${BREAK_POINT_SAFE_MIN_WIDTH})`}>
        <Spinner
          loading={loading}
          error={error}
          style={loading ? { height: '90px' } : {}}
        >
          <div className="customer-header">
            <p className={'customer-header-tenant-status h6 m-0'}>
              <span>{t('Customer:registrationStatus')}</span>

              <span className="mx-3">-</span>

              <BadgeStatus status={status} />

              <HelpButton
                message={t(`RegistrationStatusToolTips:${status}`, {
                  link: `/customers/${customer.id}/systems`
                })}
                uniqueKey="customer-status-tooltip"
              />
            </p>

            <div className={'customer-header-logo'}>
              <img
                alt="logo-tenant"
                src={getTenantLogo(get(customer, 'tenant.name'))}
              />
            </div>

            <InstallationSettings customer={ customer } />

            {customer.tenant && (
              <div className={'customer-header-tenant-loginAs'}>
                <RedirectButton customer={customer} />
              </div>
            )}
          </div>

          {(customer.status === RegistrationStatus.OK ||
            customer.status === RegistrationStatus.SETUP_OK ||
            customer.status === RegistrationStatus.USER_PASSWORD_SET ||
            customer.status === RegistrationStatus.USER_SETTINGS_SET ||
            customer.status === RegistrationStatus.USER_ACCEPTED_LICENCE) && (
            <IsOnlineIndicator />
          )}
        </Spinner>
      </MediaQuery>
    );
  }
  return null;
};

export default translate(['Common', 'Installation', 'RegistrationStatus'])(
  CustomerHeader
);
