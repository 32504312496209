import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { withApollo, WithApolloClient } from 'react-apollo';
import {
  Button,
  Col,
  Container,
  Row 
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { safeMobxInject } from '../../../../stores/storeInjectionHelpers';
import { EditPVInstallationStore } from './../../../../stores/installations/editPVInstallationStore';
import { FormInput } from './../../../../ui/FormInput/FormInput';
import { ISnackBarParams } from './../../../../stores/userFeedback/snackbarStore';

interface IProps extends RouteComponentProps {
  t: TranslationFunction;
  customerId: string;
}

interface IStoresProps {
  pvInstallation: EditPVInstallationStore,
  showSnackBar: (params: ISnackBarParams) => void;
}

const InstallationAssignmentModalSection = safeMobxInject<IStoresProps, IProps>(
  stores => ({
    pvInstallation: stores.editPVInstallation,
    showSnackBar: stores.snackBar.showSnackBar,
  }),
  ({ 
  t,
  client,
  customerId,
  history,
  pvInstallation,
  showSnackBar,
 }: WithApolloClient<IProps & IStoresProps>) => {
  const [pvInstallationId, setPvInstallationId] = useState<string>('');
  const [pdlNumber, setPdlNumber] = useState<string>('');
  const [installationOwner, setInstallationOwner] = useState<string>('');
  const [canAssign, setCanAssign] = useState<boolean>(false);

  const onPdlNumberChange = (value: string) => {
    setPdlNumber(value);
  };

  const getInstallationDetailsByPdl = async () => {
    const response = await pvInstallation.getInstallationDetailsByPdl(client, pdlNumber);

    setInstallationDetails(response);
  };

  const setInstallationDetails = (response: any) => {
    if (response) {
      let hasOwner = response.getInstallationDetailsByPdl.customers.length > 0;
      let owner = hasOwner 
        ? `${response.getInstallationDetailsByPdl.customers[0].firstName} ${response.getInstallationDetailsByPdl.customers[0].lastName}`
        : '';
      
      setPvInstallationId(response.getInstallationDetailsByPdl.id);
      setInstallationOwner(owner);
      setCanAssign(!canAssign);

      showSnackBar({ text: t('Customer:assignInstallationReadyForTransfer'), type: 'success' });
    }
  };

  const assignInstallationToCustomerByPdl = async () => {
    await pvInstallation.assignInstallationToCustomerByPdl(
      client, 
      customerId, 
      pdlNumber
    );

    history.push(
      `/devices_inscription/${customerId}/pv_installation/${pvInstallationId}`
    );
  }
  return (
    <Container>
      <Row>
        <Col xs={12} className="font-weight-bold">
          <p>{ t('Customer:assignInstallation') }</p>
        </Col>

        <Col xs={12} className="">
          <p>
            { t('Customer:assignInstallationDescription') }
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12}>
          <FormInput
            id="customerMobilePhone"
            type="text"
            name={ 'PDL' }
            value={ pdlNumber }
            onChange={ onPdlNumberChange }
          />
        </Col>
      </Row>

      { pvInstallationId && <Row>
        <Col xs={12} className="">
          <p className="font-weight-bold text-success">
            { t('Customer:assignInstallationValidInstall') }
          </p>
        </Col>

        { installationOwner && <Col xs={12} className="">
          <p>{ t('Customer:assignInstallationOwner') }: { installationOwner }</p>
        </Col> }
      </Row> }

      <Row>
        <Col xs={12} className="d-flex flex-row justify-content-end" style={{ gap: '15px' }}>
          <Button
            id={ `button-check-pdl` }
            className={ 'test-button' }
            onClick={ getInstallationDetailsByPdl }
            >
              { t('Customer:assignInstallationCheckPdl') }
          </Button>

          <Button
            id={ `button-assign-installation` }
            className={ 'btn btn-primary' }
            color="primary"
            disabled={ !canAssign }
            onClick={ assignInstallationToCustomerByPdl }
            >
              { t('Customer:assignInstallationAssignAndEdit') }
          </Button>
        </Col>
      </Row>
    </Container>
  );
});

export default withRouter(translate(['Common', 'Customer'])(
  withApollo(InstallationAssignmentModalSection)
));